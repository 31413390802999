import React from 'react';

function MathpidImpactFeatures(props) {
  return (
    <main className="container">
      <section className="top-section-features">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">예비 초등부터 중등 학생까지</h2>
            <p className="desc">전 세계 학습자를 위해 설계된 AI 기반 수학 학습 솔루션</p>
          </div>
        </div>
      </section>

      <div className="rwd-photo-area">
        <figure className="img"><img src="../../../images/impact/img_feature_main.png" alt=""/></figure>
      </div>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">1:1 맞춤화 학습</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content01.png" alt="AI 레벨 테스트"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">AI 레벨 테스트</dt>
                    <dd className="desc">단 10개의 문제만으로 AI는 95% 이상의 정확도로 수학 수준을 진단하고 수학 능력을 알려줍니다.</dd>
                  </dl>
                </div>
              </li>
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content02.png" alt="나의 학습"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">나의 학습</dt>
                    <dd className="desc">1:1 튜터의 지도를 받으며 당신에게 꼭 맞는 맞춤형 학습 여정에 빠져보세요!</dd>
                  </dl>
                </div>
              </li>
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content03.png" alt="실시간 분석"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">실시간 분석</dt>
                    <dd className="desc">실시간으로 틀린 유형과 취약점을 분석하여 다음 문제에 반영합니다.</dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">다양한 학습 도구</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content04.png"
                                                alt="단계별 개념 풀이 영상"/></div>
                  <dl className="col txt">
                    <dt className="tit">단계별 개념 풀이 영상</dt>
                    <dd className="desc">짧은 개념 풀이 영상은 복잡한 문제를 쉽게 이해할 수 있도록 도와줍니다.</dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content05.png" alt="개인화 워크북"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">개인화 워크북</dt>
                    <dd className="desc">AI 레벨테스트 결과에 따라 제공되는 맞춤형 워크북으로 학습하세요.</dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">모르는 문제 마스터</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content06.png" alt="수학 카메라"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">수학 카메라</dt>
                    <dd className="desc">모르는 문제를 화면에 쓰거나 사진을 찍으면 AI가 신속하게 해결해 드립니다.</dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content07.png"
                                                alt="단계별 개념 풀이 영상"/></div>
                  <dl className="col txt">
                    <dt className="tit">단계별 개념 풀이 영상</dt>
                    <dd className="desc">소주제별 개념 설명과 단계별 풀이 영상이 제공됩니다.</dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content08.png" alt="유사 문제"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">유사 문제</dt>
                    <dd className="desc">모르는 문제를 마스터할 수 있도록 유사 문제를 제안합니다.</dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">선생님용 기능</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content09.png" alt="학습 결과 리포트"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">학습 결과 리포트</dt>
                    <dd className="desc">학생 개개인의 취약점과 학습 수준을 분석한 리포트를 제공합니다.</dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content10.png" alt="매쓰 파워"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">매쓰 파워</dt>
                    <dd className="desc">학생의 개 학습 현황을 시각적으로 확인하세요.</dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content11.png" alt="학습 관리 시스템"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">학습 관리 시스템</dt>
                    <dd className="desc">간편한 방식으로 학생 맞춤 관리가 가능한 교사용 학습 관리 시스템을 제공합니다.</dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">실시간 글로벌 랭킹</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content12.png"
                                                alt="실시간 글로벌 랭킹"/></div>
                  <dl className="col txt">
                    <dt className="tit blind">실시간 글로벌 랭킹</dt>
                    <dd className="desc">글로벌 랭킹에 도전하면서 스스로 동기 부여하는 학생들을 발견합니다.</dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MathpidImpactFeatures;