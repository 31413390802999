import React from 'react';
import MathpidContent from "../../../component/mathpid_content";
import {useAppContext} from "../../../App";

function MathpidMain(props) {
  const { handlePage } = useAppContext();

  return (
    <main className="container">
      <section className="main-top-section">
        <div className="main-visual bg-primary">
          <div className="frame-inner">
            <div className="grid-column">
              <div className="col-12 view-column">
                <div className="video-box">
                  <div className="video">
                    <iframe width="100%" height="100%"
                            src="https://www.youtube.com/embed/reUjOYfx43M?si=xbnWl_Qp6JXJqjRq"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 copy-column">
                <div className="main-copy-area">
                  <div className="main-copyright">
                    <h2 className="title">Struggling to<br/>close learning gaps?</h2>
                    <p className="sub-copy-type1">Mathpid helps students Build <br className="rwd-br-mo"/>Confidence in
                      Math</p>
                    <p className="sub-copy-type2">AI-Powered Math Learning Solution for Ages 5 to 15</p>
                  </div>
                  <div className="main-btn">
                    <a href="https://class.mathpid.com/member/createAccount?type=lms" target="_blank"
                       className="btn-basic-md btn-point"><strong>TRY IT FREE</strong></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="quick-links">
          <div className="frame-inner ui-quicklink-slide swiper">
            <ul className="quick-links-list swiper-wrapper">
              <li className="swiper-slide">
                <div className="quick-link-item">
                  <figure className="img">
                    <img src="../../../images/main/img_main_educators.png" alt="Character"/>
                  </figure>
                  <div className="grid-column">
                    <div className="col txt">
                      <strong>For Educators</strong>
                      <p>Powerful teaching solution</p>
                    </div>
                    <div className="col btn">
                      <a onClick={() => handlePage('/educators')} className="btn-basic-xs btn-primary"><span>Learn More</span></a>
                    </div>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="quick-link-item">
                  <figure className="img">
                    <img src="../../../images/main/img_main_learners.png" alt="Character"/>
                  </figure>
                  <div className="grid-column">
                    <div className="col txt">
                      <strong>For Learners</strong>
                      <p>Math learning made easy and fun</p>
                    </div>
                    <div className="col btn">
                      <a onClick={() => handlePage('/learners')} className="btn-basic-xs btn-primary"><span>Learn More</span></a>
                    </div>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="quick-link-item">
                  <figure className="img">
                    <img src="../../../images/main/img_main_try.png" alt="Character"/>
                  </figure>
                  <div className="grid-column">
                    <div className="col txt">
                      <strong>Try Mathpid</strong>
                      <p>Trusted by 3M users worldwide</p>
                    </div>
                    <div className="col btn">
                      <a href="https://class.Mathpid.com" target="_blank" className="btn-basic-xs btn-primary"><span>Learn More</span></a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>

      <section className="main-section">
        <h3 className="blind">About Mathpid</h3>
        <div className="main-introduce introduce-grid-wrap">
          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/main/img_main_intro01.png" alt="소개 이미지 1"/>
            </figure>
            <div className="text">
              <h4 className="head">Personalized Learning <br className="rwd-br-mo"/>for Each Student: <br
                className="rwd-br-mo"/>Bridge the Learning Gap</h4>
              <p className="sub">Our comprehensive 10-question AI Level Test accurately assesses each student's
                strengths and weaknesses, offering personalized learning classes.</p>
              <p className="desc">Real-time adjustments to question difficulty based on student comprehension, <br
                className="rwd-br-mo"/>providing targeted practice on weaker areas and bridging learning gaps.</p>
            </div>
          </div>

          <div className="introduce-grid-type2">
            <figure className="img">
              <img src="../../../images/main/img_main_intro02.png" alt="소개 이미지 2"/>
            </figure>
            <div className="text">
              <h4 className="head">Time-Saving for Educators : <br className="rwd-br-mo"/>Learning Management System
              </h4>
              <p className="sub">Easy-to-use dashboard keeps you informed of student progress and achievements, allowing
                you to deliver targeted assignments and support exactly when needed.</p>
              <p className="desc">AI-powered personalization tailors assignments to each student's ability, reducing
                teachers' workloads while providing supplementary classes to recover learning loss.</p>
            </div>
          </div>

          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/main/img_main_intro03.png" alt="소개 이미지 3"/>
            </figure>
            <div className="text">
              <h4 className="head">Keep Students Engaged with <br className="rwd-br-mo"/>Mathpid App, Anytime and
                Anywhere</h4>
              <p className="sub">From school to home, learning can happen anywhere and anytime, helping to build and
                enhance students' arithmetic skills.</p>
              <p className="desc">Mobile, tablet, or Chromebook — empower students to learn on their favorite devices,
                giving them the freedom to study wherever they are.</p>
            </div>
          </div>
        </div>
      </section>

      <MathpidContent />

      <section className="section">
        <div className="frame-inner">
          <h3 className="title-section">Multi-Award Winning Online Math Program</h3>
          <div className="awards-list">
            <ul className="grid-row">
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards01.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards02.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards03.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards04.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards05.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards06.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards07.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards08.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards09.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards10.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards11.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards12.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards13.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards14.png" alt="Awards"/></figure>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MathpidMain;