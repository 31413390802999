import React from 'react';

function MathpidTerms(props) {
  return (
    <main className="container">
      <section className="top-section-terms">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">서비스 약관</h2>
          </div>
        </div>
      </section>

      <section className="section no-gutter">
        <div className="terms-policy-wrap">
          <h3>[매쓰피드 - 이용약관]</h3>

          <h4>제 1조 (목적)</h4>
          <p>이 약관은 주식회사 웅진씽크빅(이하 '회사<span>')</span>이 운영하는 매쓰피드 어플리케이션(이하 '매쓰피드<span>')</span>에서 제공하는 서비스를 이용함에 있어 회사와
            회원의 권리, 의무 및 책임 사항 등을 규정함을 목적으로 합니다.</p>

          <h4>제 2조 (정의)</h4>
          <ol>
            <li>'회사'라 함은 '콘텐츠'산업과 관련된 경제활동을 영위하는 자로서 콘텐츠 및 제반 서비스를 제공하는 자이며, 이 약관에서는 주식회사 웅진씽크빅을 말합니다.</li>
            <li>'매쓰피드'란<span> AI</span>를 이용한 연산학습 및 각종 정보 관리 및 이벤트 참여 등과 같은 회원에게 다양한 서비스 및 혜택을 담고 있는 모바일 앱을 말합니다.</li>
            <li>'서비스'란 접속 가능한 유무선 통신기기의 종류와는 상관없이 이용 가능한 '회사'가 제공하는 모든 서비스를 의미합니다.</li>
            <li>'회원'이란 유무선 통신기기에 '매쓰피드'을 설치하고 접속하여 사용하는 자를 말하며, 회사에 개인정보를 제공하여 회원가입을 한 자로서, 회원 아이디를 부여받아 본 약관에 따라 회사가
              매쓰피드 앱 서비스를 이용하는 자를 말합니다.
            </li>
            <li>'회원 아이디(이하<span> 'ID'</span>라 한다<span>)'</span>란 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 이메일을 의미합니다.
            </li>
            <li>'비밀번호'란 회원이 부여받은<span> ID</span>와 일치된 회원임을 확인하고, 회원의 개인정보를 보호하기 위하여 회원이 정한 영문, 숫자, 특수문자
              조합(최소<span> 8</span>자 이상)을 의미합니다.
            </li>
            <li>'회원가입'이라 함은 매쓰피드 서비스를 이용하고자 하는 자<span>('</span>고객'이라고도 합니다)가 매쓰피드 이용약관에 동의하고 회사와 그 이용계약을 체결하는 것을 의미합니다.
              회원가입을 하는 경우 고객은 매쓰피드 서비스 회원이 됩니다.
            </li>
            <li>'회원탈퇴'라 함은 회원이 매쓰피드 이용약관 체결로 발생한 제반 권리와 의무 등의 법률관계를 자의로 영구히 종료하거나, 포기하는 것을 의미합니다.</li>
            <li>'유료 서비스'라 함은 회사가 제공하는 별도의 결제방식을 이용하여 이용요금을 지급한 후에 사용할 수 있는 매쓰피드 서비스를 의미하며, 유료서비스의 세부내용은 결제 안내 페이지에 자세히
              기재되어 있습니다.
            </li>
            <li>'유료회원'이라 함은 유료서비스를 구매한 회원을 말합니다.</li>
            <li>'무료회원'이라 함은 유료회원이 아닌 회원으로 회원가입 이후에 기본적으로 모든 회원에게 부여되는 자격을 가진 회원을 의미합니다.</li>
            <li>'결제'라 함은 회원이 매쓰피드 유료 서비스를 이용하기 위하여 회사가 정한 일정 금액을 앱스토어를 통해 결제하는 것을 의미합니다.</li>
            <li>'구매'라 함은 회원이 이용하고자 하는 유료서비스를 선택하여 지불수단을 통한 결제를 함으로써 그 대가를 지급하는 행위를 의미합니다.</li>
          </ol>
          <p>이 약관에서 사용하는 용어의 정의는 상기에서 정한 것을 제외하고는 거래관행 및 관계 법령을 따릅니다.</p>

          <h4>제 3조 (신원정보 등의 제공)</h4>
          <p>회사는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함<span>), </span>전화번호, 모사전송번호, 전자우편주소,
            사업자등록번호, 통신 판매업 신고 번호 및 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 매쓰피드 서비스 초기화면에 게시합니다. 다만, 약관은 이용자가 별도의 연결화면을 통하여 볼 수
            있도록 할 수 있습니다.</p>

          <h4>제 4조 (약관의 효력/변경 등)</h4>
          <ol>
            <li>이 약관은 서비스를 이용하기를 희망하는 회원이 동의함으로써 효력이 발생하며, 회원이 이 약관에 대하여 "동의하기" 버튼을 클릭한 경우, 이 약관의 내용을 충분히 이해하고 그 적용에 동의한
              것으로 봅니다.
            </li>
            <li>회사는 매쓰피드 서비스 내 변경사항 또는 사업방식의 변화를 반영하거나(예<span>: </span>새로운 서비스, 기능, 기술 또는 유료콘텐츠 서비스 등을 추가하거나 기존의 것들을
              삭제하는 경우<span>), </span>법령, 규제 또는 보안상의 이유가 있거나, 매쓰피드 서비스 악용 또는 위해를 방지하기 위해 본 약관을 변경할 수 있습니다.
            </li>
            <li>회사가 약관을 개정할 경우에는 적용 일자 및 개정사유를 명시하여 본<u> 매쓰피드 초기화면에 적용일자<span> 15</span>일부터 적용일자 전일까지 공지합니다.</u> 다만 회원에게
              불리하게 약관 내용을 변경하는 경우에는 최소<span> 30</span>일 이상의 유예기간을 두고 공지합니다.
            </li>
            <li>회사가 이 약관을 개정하는 경우 그 개정약관은 적용일자 이후에 체결되는 계약에 적용되며, 기존 회원들도 충분한 유예기간을 두고 공지한 후 소급적용 됩니다. 개정 약관의 적용/시행일까지
              회원이 거부 의사를 표시하지 아니할 경우 약관의 개정에 동의한 것으로 간주한다는 내용을 알렸으나, 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회사는 회원이 적용/시행
              일자 부로 변경 약관에 동의한 것으로 간주합니다. 개정/변경 약관에 대하여 거부의사를 표시한 회원은 계약의 해지 또는 회원 탈퇴를 선택할 수 있습니다.
            </li>
            <li>이 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다. 단, 이 약관의 일부 조항은 회원이 탈퇴 후에도 유효하게 적용될 수 있습니다.</li>
            <li>이 약관은 회사가 제공하는 각 서비스 별 운영 규칙(개인정보 보호정책 등)과 함께 적용됩니다. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 국제사업과 무관하게 대한민국
              전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 공정거래위원회가 정하는 '전자상거래등에서의 소비자 보호지침'및 관계 법령 또는 상관례에 따릅니다.
            </li>
          </ol>

          <h4>제 5조 (회원가입)</h4>
          <ol>
            <li>회원가입은 이용자가 약관의 내용에 대하여 동의를 하고 회원가입 신청을 한 후 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</li>
            <li>회원가입 신청서에는 다음 사항을 기재해야 합니다. 다음 제<span>1</span>호 또는 제<span>2</span>호의 사항은 필수 기재사항이며, 그 외의 사항은 선택 사항입니다.
              <ol>
                <li>이메일 주소</li>
                <li>가입자 정보(이름 또는 닉네임)</li>
                <li>이벤트 진행 시 받는 정보(이름, 연락처, 주소)</li>
                <li>개인정보 수집 제공 및 활용 동의</li>
                <li>기타 회사가 필요하다고 인정하는 사항은 별도의 동의를 받습니다.</li>
              </ol>
            </li>
            <li>회사는 상기 이용자의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있습니다.
              <ol>
                <li>실명이 아니거나 타인의 명의를 이용하여 신청한 경우</li>
                <li>이용계약 신청서의 내용을 허위로 기재한 경우</li>
                <li>사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한 경우</li>
                <li>부정한 용도로 본 서비스를 이용하고자 하는 경우</li>
                <li>영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우</li>
                <li>기타 회사가 정한 등록신청 요건이 미비한 경우</li>
                <li>가입 신청자가 본 약관에 의하여 이전에 회원자격을 잃은 적이 있는 경우</li>
                <li>기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
              </ol>
            </li>
            <li>회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</li>
            <li>제<span>3</span>항과 제<span>4</span>항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 이를 신청자에게 알려야 합니다. 회사의 귀책사유 없이
              신청자에게 통지할 수 없는 경우에는 예외로 합니다.
            </li>
            <li>회원가입계약의 성립 시기는 회사의 승낙이 이용자에게 도달한 시점으로 합니다.</li>
          </ol>

          <h4>제 6조 (미성년자의 회원가입에 관한 특칙)</h4>
          <ol>
            <li>만<span> 14</span>세 미만의 이용자는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입을 신청하고 본인의 개인정보를
              제공하여야 합니다.
            </li>
            <li>회사는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은<span> 14</span>세 미만 이용자에 대하여는 가입을 취소 또는 허락하지 않습니다.</li>
            <li>만<span> 14</span>세 미만 이용자의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며 이러한 경우에 회사는
              지체없이 필요한 조치를 해야 합니다. 이때 회사는 법정대리인임을 입증할 수 있는 문서 등의 확인서류 제출을 요청할 수 있습니다.
            </li>
          </ol>

          <h4>제 7조 (회원정보의 변경)</h4>
          <ol>
            <li>회원은 계정 관리를 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. (이메일 주소 및 비밀번호 등 변경 가능)</li>
            <li>회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정하거나 이메일 등 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.</li>
            <li>제<span>2</span>항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
          </ol>

          <h4>제 8조 (회원의 '아이디' 및 '비밀번호'의 관리에 대한 의무)</h4>
          <ol>
            <li>회원의 '아이디'와 '비밀번호'에 관한 관리책임은 회원에게 있으며, 이를 제<span>3</span>자가 이용하도록 하여서는 안 됩니다.</li>
            <li>회원은 '아이디'및 '비밀번호'가 도용되거나 제<span>3</span>자에 의해 사용되고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.</li>
            <li>제<span>2</span>항의 상황에 해당 회원이 회사에 그 사실을 통지하지 않거나 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
          </ol>

          <h4>제 9조 (회원에 대한 통지)</h4>
          <ol>
            <li>회사가 회원에 대한 통지를 하는 경우 회원이 지정한 이메일 주소로 할 수 있습니다.</li>
            <li>회사는 회원 전체에 대한 통지의 경우<span> 7</span>일 이상 회사의 게시판에 게시함으로써 제<span> 1</span>항의 통지에 대신할 수 있습니다. 다만, 회원 본인의
              거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 합니다.
            </li>
          </ol>

          <h4>제 10조 (회원탈퇴 및 자격 상실 등)</h4>
          <ol>
            <li>회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다. 단, 탈퇴한 회원은 탈퇴 일로부터 같은 아이디로 회원가입을 할 수 없습니다.</li>
            <li>회원이 다음 각 호의 사유에 해당하면, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.
              <ol>
                <li>가입 신청 시에 계약에 위반되는 허위 사실을 기입하였을 경우</li>
                <li>다른 사람의 회사의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
                <li>회사를 이용하여 기타 관련 법률과 이 약관이 금지하는 행위를 하거나 미풍양속에 반하는 행위를 하는 경우</li>
                <li>기타 이 약관의 회원의 의무를 위반하는 경우</li>
              </ol>
            </li>
            <li>회사가 회원자격을 제한 &middot; 정지시킨 후, 동일한 행위가<span> 2</span>회 이상 반복되거나<span> 30</span>일 이내에 그 사유가 시정되지 아니하는 경우
              회사는 회원자격을 상실시킬 수 있습니다.
            </li>
            <li>회사가 회원자격을 상실시키는 경우에는 회원등록을 말소하며, 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.</li>
            <li>제<span> 2</span>항의 경우, 회사는 회원에게 손해배상을 청구할 수 있습니다.</li>
          </ol>

          <h4>제 11조 (개인정보의 보호 및 관리)</h4>
          <ol>
            <li>회사는 서비스를 제공하는 데 필요한 회원의 개인정보를 수집할 수 있습니다.</li>
            <li>회사는 회원의 개인정보를 수집 &middot; 이용하는 때에는 그 목적을 고지하고 동의를 받습니다.</li>
            <li>회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제<span> 3</span>자에게 제공하는 경우에는 이용 &middot; 제공단계에서
              당해 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
            </li>
            <li>회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 회원이 오류의 정정을
              요구한 경우에는 회원은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
            </li>
            <li>회사는 개인정보 보호를 위하여 회원의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 회원의 개인 정보의 분실, 도난, 유출, 동의 없는
              제<span> 3</span>자 제공, 변조 등으로 인한 회원의 손해에 대하여 모든 책임을 집니다.
            </li>
            <li>회사 또는 그로부터 개인정보를 제공받은 제<span> 3</span>자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</li>
            <li>회사는 개인정보의 수집 &middot; 이용 &middot; 제공에 관한 동의 란을 미리 선택한 것으로 설정해 두지 않습니다. 다만, 개인정보의
              수집 &middot; 이용 &middot; 제공에 관한 동의를 회원이 거절하는 경우 회원은 매쓰피드 서비스의 이용을 일부 제한 받을 수 있습니다.
            </li>
            <li>회사는 회사의 귀책 없이 회원의 귀책사유로 인하여 회원의 정보가 노출된 경우 이에 대해서는 책임을 지지 않습니다.</li>
            <li>기타 개인정보의 제공 등 개인정보 취급과 관련한 내용은 '개인정보처리방침'등에서 자세히 고지하며, 약관과 별도의 동의를 받습니다.</li>
          </ol>

          <h4>제 12조 (회원의 의무)</h4>
          <ol>
            <li>회원은 관련 법령, 본 약관의 각 규정, 이용안내 및 서비스와 관련하여 공지하거나 통지한 주의사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.
            </li>
            <li>회원은 내용별로 회사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.</li>
            <li>회원은 회사의 사전승낙 없이는 서비스를 이용하여 영업 기타 영리적 목적의 활동을 할 수 없으며, 그 영업활동의 결과와 회원이 약관에 위반한 영업활동을 하여 발생한 결과에 대하여 회사는
              책임을 지지 않습니다. 회원은 이와 같은 영업활동으로 회사가 손해를 입었을 때 회사에 대하여 손해배상 의무를 집니다.
            </li>
            <li>회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용 계약상의 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</li>
            <li>회원은 서비스 이용과 관련하여 다음 각 호에 해당하는 행위를 하여서는 안 됩니다.
              <ol>
                <li>신청 또는 변경 시 허위내용의 기재</li>
                <li>타인의 정보도용</li>
                <li>회사가 게시된 정보의 변경</li>
                <li>회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</li>
                <li>회사와 기타 제삼자의 저작권 등 지식재산권에 대한 침해</li>
                <li>회사 및 기타 제삼자의 명예를 손상하거나 업무를 방해하는 행위</li>
                <li>외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 미풍양속에 반하는 정보를 회사의 사이트에 공개 또는 게시하는 행위</li>
                <li>불법적이거나 부당한 행위</li>
                <li>기타 관계되는 법령에 위배되는 행위</li>
              </ol>
            </li>
            <li>회원은 본 약관에서 규정하는 사항 및 주의사항을 준수하여야 하며, 회원이 회사가 규정 사항 및 주의사항을<span> 2</span>회 이상 위반하거나<span> 30</span>일 이내에
              그 사유가 시정되지 아니하는 경우 회사는 회원 자격을 상실 시킬 수 있습니다.
            </li>
            <li>회사가 회원자격을 상실시키는 경우에는 회원등록을 말소하며, 이 경우 회원에게 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.</li>
          </ol>

          <h4>제 13조 (서비스 이용계약의 성립 및 승낙의 제한)</h4>
          <ol>
            <li>서비스에 대한 이용계약은 이 약관에 동의한다는 의사표시(동의함을 선택)와 함께 이용신청을 하고 회사가 이를 승낙함으로써 비로소 성립합니다.</li>
            <li>회원은 서비스 이용계약을 체결하기 전에 해당 서비스에 대하여 이 약관에서 회사가 명시, 고지하는 사항을 숙지하고, 착오 없이 정확히 거래할 수 있도록 하여야 합니다.</li>
            <li>회사와 회원 간 서비스 이용계약은 회사의 승낙이 회원에게 도달한 시점에 성립합니다.</li>
            <li>회사는 이용약관 제<span> 13</span>조(이용 신청에 대한 승낙의 제한)에서 정하는 사유가 발생하는 경우, 이용신청을 승낙하지 아니하거나 소정의 조건 성취 또는 제한 사유가
              해소될 때까지 일정 기간 동안 승낙을 유보할 수 있습니다.
            </li>
            <li>회사는 이용약관 제<span> 13</span>조(서비스 이용계약의 성립 및 승낙의 제한)의 기재사항 이외에 회원의 서비스 이용에 필요한 최소한의 정보를 수집할 수 있습니다. 이를 위해
              회사가 문의한 사항에 대해 회원은 성실하게 알립니다. 회사는 이 경우 수집하는 회원의 정보를 매쓰피드 이용약관, 개인정보취급방침 및 정보통신망의 이용촉진 및 정보보호 등에 관한 법률이 정한
              바에 따라 이용, 관리합니다.
            </li>
          </ol>

          <h4>제 14조 (결제수단 등)</h4>
          <ol>
            <li>회원이 유료 서비스의 결제를 위하여 이용할 수 있는 지불 수단은 다음 각 호와 같습니다.
              <p>① 앱스토어(애플 앱스토어/구글 플레이 스토어)결제 수단</p>
            </li>
            <li>유료 서비스 결제를 통해 회원은 아래와 같은 행위를 할 수 있습니다.
              <p>① 유료서비스의 가입 및 유료서비스 이용</p>
            </li>
            <li>유료 서비스(이하 통칭하여 "<strong>상품</strong>"이라 합니다)를 구매하는 경우, 모든 지급 관련 질문, 문제, 불만 등은 귀하가 사용하는 앱스토어 등
              결제 &nbsp;서비스의 약관에 따라 처리될 것이며, 회사는 이에 대해 관여할 권한이 없으며 어떠한 법적 책임도 부담하지 않습니다<span>.&nbsp;</span></li>
          </ol>

          <h4>제 15조 (유료서비스와 유료회원)</h4>
          <ol>
            <li>유료서비스는 회원의 유료 서비스 결제일로부터 유료서비스는 개시되며 회사의 기술적 사유 등 기타 사정에 의하여 서비스를 개시할 수 없는 경우에는 제<span>4</span>조(약관의
              효력/변경 등)의 방법에 따라 회원에게 사전 공지(게시판 등을 통함)합니다.
            </li>
            <li>회사는 다음 각 호와 같은 유료서비스를 제공하며, 회사의 사정, 기타 모든 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다. 각 유료서비스의 내용은 유료서비스 구매 페이지, 고객
              센터<span>(FAQ) </span>등에서 회원에게 자세히 표시하고 있습니다.

              <p>① 결제 서비스의 이용 기간에 따른 분류</p>
              <ul>
                <li>개별 구매 상품<span> : </span>서비스를 이용하는 "회원"이 특정 상품을 유료로 결제하여 이용할 수 있는 상품</li>
                <li>기간제 상품<span>: </span>서비스를 이용하는 "회원"이 유료로 결제하여 일정 기간동안 이용할 수 있는 상품</li>
              </ul>

              <p>② 개별 <span>&amp; </span>기간제 구매 상품 정책</p>
              <ul>
                <li>개별 구매 상품은 앱스토어 결제로만 진행되며, 구매 이후 환불 등의 정책은 각각의 앱스토어 환불 정책에 따릅니다.</li>
              </ul>

              <p>③ 제<span>3</span>자 결제에 대한 지불 책임<span>&nbsp;</span></p>
              <ul>
                <li>회사의 고의 또는 중과실로 인하여 귀하의 개인정보가 유출됨으로 인하여 상품이 구매되는 경우가 아닌 한, 회원은 회원의 계정과 관련하여 발생하는 모든 청구에 대한 책임을
                  부담합니다<span>.&nbsp;&nbsp;</span></li>
                <li>회원이 단말기 및 앱스토어의 비밀번호 설정 기능 등을 이용하지 않거나 부주의하게 노출하여 발생하는 제<span>3</span>자의 결제를 포함하여 귀하의 명의 또는 개인정보
                  도용으로 인하여 발생하는 제<span>3</span>자의 결제에 대하여 회사는 책임을 부담하지 않습니다<span>.&nbsp;</span></li>
                <li>회원의 지인, 자녀, 가족이 귀하의 단말을 이용하여 상품을 구매하지 않도록 주의를 기울이시기 바랍니다. 귀하의 지인, 자녀, 가족이 귀하의 동의 없이 또는 귀하의 뜻에 반하여
                  상품을 구매하는 경우에도 관련 법률이 달리 규정하고 있는 경우를 제외하고는 귀하는 요금 지불 책임을 지며, 그에 대하여 회사는 어떠한 책임도 부담하지
                  않습니다<span>.&nbsp;</span></li>
              </ul>

              <p>④ 환불</p>
              <ul>
                <li>본 약관에 명시되거나 법률에 따라 달리 규정되지 않은 한, 다음 각 호의 상품을 포함한 모든 상품은 구매 후 어떠한 경우에도 환불이 불가합니다.
                  <ol>
                    <li>본 약관 위반 또는 위법행위로 서비스 이용이 정지된 이용자의 보유 상품<span>&nbsp;</span></li>
                    <li>이미 전부 또는 부분 사용되었다고 인정되는 상품(일부가 사용된 기간제 상품)</li>
                    <li>구매한 날로부터<span> 7</span>일이 경과한 상품</li>
                    <li>본 서비스로부터 탈퇴한 이용자의 상품</li>
                    <li>단말의 분실 또는 도난, 이용자의 부주의에 의하여 제<span>3</span>자가 결제한 상품</li>
                  </ol>
                </li>
                <li>회원이 거주하는 국가의 법률에 따라 허용되는 모든 상품의 환불은 귀하가 상품의 구매에 이용한 앱스토어 결제의 환불 규정에 따라 처리되고 이에 따라 제한될 수 있습니다.</li>
              </ul>
            </li>
          </ol>

          <h4>제 16조 (유료서비스의 정지, 중단)</h4>
          <ol>
            <li>회사는 원칙적으로 연중무휴<span> 1</span>일<span> 24</span>시간 유료서비스를 제공합니다.</li>
            <li>회사는 이용자에 대한 서비스 개선을 목적으로 하는 설비 점검 및 보수 시에는 유료서비스의 전부 또는 일부의 제공을 제한, 중지, 중단할 수 있습니다. 이 경우 회사는 가능한 한 그
              중단사유, 중단 기간 등을 이 약관 제<span>4</span>조(약관의 효력/변경 등)의 방법을 통하여 사전에 회원에게 공지합니다.
            </li>
          </ol>

          <h4>제 17조 (회원의 의무, 위반시 회사의 조치 등)</h4>
          <ol>
            <li>회원은 회사가 제공하는 서비스 이용 시 관계법령, 약관, 세부이용지침, 서비스 이용안내 및 사이트 내 공지한 주의사항, 회사가 서비스 이용과 관련하여 회원에게 통지하는 사항 등을 준
              수하여야 하며, 기타 회사 및 타인의 명예를 훼손하거나 서비스 운영 기타 업무수행에 지장을 초 래하는 행위를 해서는 안됩니다.
            </li>
            <li>회원은 아이디 및 비밀번호를 관리할 책임이 있으며, 본인이 아닌 타인이 사용하게 하여서는 안됩니다.</li>
            <li>회원은 회사가 사전에 허용한 경우를 제외하고는 서비스를 영업활동 등 영리목적으로 이 용하거나 이 약관에서 금지하는 행위를 하거나 허용하는 범위를 벗어난 이용행위를 하여서는 안 됩니다.
            </li>
            <li>회원은 서비스 이용과정에서 위법행위 또는 선량한 풍속 기타 사회질서에 반하는 행위를 하여서는 안됩니다.</li>
            <li>회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 서비스의 이용 이외의 목적으로 사용하여서는 안됩니다.</li>
            <li>회원은 학습 콘텐츠를 불법적으로 유포, 공유하여서는 아니되며, 이를 위반하여 발생한 제반 문제에 대한 책임은 회원 본인에게 있습니다. (이 경우, 국내외 제<span>3</span>자의
              저작권 등을 침해하는 행위로서 회사가<span> IP </span>접속차단 등 기술적인 조치를 통하여 타인에 대한 권리 침해 방지 조치를 취하였음에도 불구하고 이용자가 고의로 또는 회사를
              기망하는 수단과 방법을 통하여 매쓰피드에 접속하는 등 제<span>3</span>자의 저작권 등을 침해하는 행위를 포함합니다<span>.)</span></li>
            <li>회사는 본조 제<span>1</span>항 내지 제<span>6</span>항의 회원의 위반 행위가 있는 경우 해당 회원에 대하여 위반 사실을 알리고 서비스
              제공을<span> 1</span>개월간 정지시킬 수 있고, 동위반행위가 재발하면 서비스 이용계약을 해제 또는 해지할 수 있습니다. 본 항 회사의 의사표시는 회원에게 도달한 날로부터 효력이
              발생합니다. 회사의 해제/해지 및 이용 정지에 대하여 회원은 회사가 정한 절차에 따라 이의 신청 할 수 있습니다. 이 경우 이용자가 자신의 고의, 과실 없었음을 입증하거나 회원의 이의가
              정당하다고 회사가 인정하는 경우 회사는 계정을 정지한 기간만큼 이용 기간을 연장합니다.
            </li>
            <li>회원은 서비스 이용과 관련하여 진실한 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 서비스 이용에 제한을 받을 수 있습니다.</li>
            <li>민법에 따른 미성년회원이 회사의 서비스를 이용하고자 하는 경우, 법정대리인(부모님)의 동의를 얻거나 계약 체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수
              있습니다. 만<span> 14</span>세 미만 아동이 서비스를 이용하기 위한 정보 제공 시에는 법정 대리인의 동의를 받아야 합니다.
            </li>
          </ol>

          <h4>제 18조 (광고게재)</h4>
          <ol>
            <li>매쓰피드는 타 어플리케이션에 하이퍼링크(하이퍼링크의 대상에는 문자, 그림 및 동영상 등이 포함됨)방식 등에 의해 연결시킬 수 있습니다.</li>
            <li>회사는 회원에게 최상의 매쓰피드 서비스를 원활하게 제공하기 위한 재정기반을 마련하기 위하여 상업용 광고를 화면에 게재하거나 이메일
              또는<span> DM(</span>서신<span>), </span>푸시 메시지 등을 이용하여 개별 회원에게 보낼 수 있습니다. 단, 수신 거절의 의사를 명백히 표시한 회원에 대해서는 더 이상
              발송하지 않습니다.
            </li>
            <li>회사는 본 매쓰피드 서비스를 통환 광고주의 판촉활동에 회원이 직접 참여함으로써 발생하는 손해에 대하여는 아무런 책임을 부담하지 않습니다.</li>
          </ol>

          <h4>제 19조(손해배상)</h4>
          <ol>
            <li>회사는 서비스의 결함에 의하여 회사가 제공하는 서비스의 내용인 콘텐츠가 손상, 훼손, 삭제되어 서비스 이용에 손해를 입은 회원에게 해당 콘텐츠의 복원으로 배상합니다. 회원에게 손 해가
              발생한 경우 회사는 회원에게 실제 발생한 손해 만을 배상합니다. 다만, 회사의 고의 또는 과실 없이 회원에게 발생한 일체의 손해에 대하여는 책임을 지지 아니합니다. 회사는 이 약관에서
              명시되지 아니한 회사의 귀책사유로 인하여 서비스의 이용 회원에게 손해가 발생한 경우 회 사의 배상 책임과 관련하여 회사는 '콘텐츠 이용자 보호지침'의 관련 규정 및 기타 상관례를 따릅니다.
            </li>
            <li>고객이 이 약관의 이용 제한 관련 각 규정에 근거, 서비스의 이용이 제한되거나 이용계약이 해지된 경우, 고객이 보유한 모든 디지털 콘텐츠의 사용권은 상실되며 회사는 해당 디지털 콘텐츠의
              구매대금을 반환할 의무를 부담하지 아니합니다.
            </li>
            <li>회원이 이 약관 상의 의무를 위반함으로 인하여 회사에 손해가 발생한 경우 또는 회원이 서비스를 이용하면서 회사에 손해를 입힌 경우, 회원은 회사에 그 손해를 배상하여야 합니다.</li>
          </ol>

          <h4>제 20조 (면책)</h4>
          <ol>
            <li>회사는 다음 각 호의 사유로 인하여 회원 또는 제삼자에게 발생한 손해에 대하여는 그 책임을 지지 아니합니다.
              <ol>
                <li>천재지변 또는 이에 따르는 불가항력으로 인해 서비스를 제공할 수 없는 경우</li>
                <li>회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 한 경우</li>
                <li>회사의 관리 영역이 아닌 공중통신선로의 장애로 서비스이용이 불가능한 경우</li>
                <li>기타 회사의 귀책사유가 없는 통신서비스 등의 장애로 인한 경우</li>
              </ol>
            </li>
            <li>회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것, 서비스에 게시된 게시물에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임을 지지 않습니다.
            </li>
            <li>회원이 화면에 게재한 리뷰, 평점, 정보, 자료 등의 내용에 관한 신뢰도 또는 정확성 등 내용에 대하여는 해당 회원이 책임을 부담하며, 회사는 내용의 부정확 또는 허위로 인해 회원 또는
              제<span> 3</span>자에게 발생한 손해에 대하여는 아무런 책임을 부담하지 않습니다.
            </li>
            <li>회사는 회원 상호 간 또는 회원과 제<span>3</span>자 상호간에 서비스와 관련하여 발생한 분쟁에 대하여 개입할 의무가 없으며, 회사에 귀책사유가 없는 한 이로 인하여 발생한
              손해를 배상할 책임이 없습니다.
            </li>
          </ol>

          <h4>제 21조 (분쟁의 해결)</h4>
          <ol>
            <li>회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구인 고객 상담실<span>(1577-1500)</span>을 운영합니다.</li>
            <li>본 약관은 국제사법 조항에 관계없이 대한민국이 법률이 적용됩니다. 회원이 회사의 매쓰피드 서비스 이용과 관련하여 발생한 분쟁, 논쟁 또는 의견 차이가 원만히 합의되지 않을 경우, 해당
              사안에 대해 대한민국의 민사소송법에 따른 법원을 관할법원으로 적용됩니다.
            </li>
            <li>해외에 주소나 거소가 있는 회원의 경우 회사와 회원 간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할 법원으로 합니다.</li>
            <li>회사는 회원이 약관을 보다 쉽게 이해할 수 있도록 본 약과의 영문본을 제공할 수 있지만, 만일 영문본과 국문본의 내용에 상충이 있을 경우에는 국문본의 내용이 우선하여 적용됩니다.</li>
          </ol>

          <p><u>[</u><u>시행일자<span>] (</span>시행일)본 약관은<span> 2023</span>년<span> 11</span>월<span> 23</span>일 부터 적용됩니다.
          </u></p>
        </div>
      </section>
    </main>
  );
}

export default MathpidTerms;