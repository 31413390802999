import React from 'react';
import {useAppContext} from "../App";

function MathpidContent(props) {

  const {langCd} = useAppContext();
  return (
    langCd === 'ko'
      ? (
        <section className="section section-bg-grey">
          <div className="frame-inner">
            <h3 className="title-section">선생님들의 사용후기</h3>
            <div className="speech-boxes-list">
              <ul className="grid-column">
                <li className="col">
                  <div className="speech-box">
                    <div className="inner">
                      <div className="message">
                        학습 손실이 있는 학생에게 개인화된 학습을 제공하여 만족스럽고, 타사 대비 개인별 취약점과 피드백을 양껏 제공해주는게 마음에 듭니다.
                      </div>
                      <div className="bottom">
                        <figure className="flag">
                          <img src={`${process.env.PUBLIC_URL}images/icons/flag_usa.png`} alt="USA"/>
                        </figure>
                        <div className="info">
                          <span className="name">Erin Hampton</span>
                          <span className="nation">6thGrade Math Teacher / USA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="col">
                  <div className="speech-box">
                    <div className="inner">
                      <div className="message">
                        학기 초에 AI Level test를 통해 개개인별 학생 수준을 확인하는 용도로서 훌륭합니다.
                      </div>
                      <div className="bottom">
                        <figure className="flag">
                          <img src={`${process.env.PUBLIC_URL}images/icons/flag_usa.png`} alt="USA"/>
                        </figure>
                        <div className="info">
                          <span className="name">Nichole Myers</span>
                          <span className="nation">6thGrade Math Teacher / USA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="col">
                  <div className="speech-box">
                    <div className="inner">
                      <div className="message">
                        각 대주제별로 소주제가 많아서 만족스럽고 학생들이 notepad 기능을 유용하게 쓰고 있습니다.
                      </div>
                      <div className="bottom">
                        <figure className="flag">
                          <img src={`${process.env.PUBLIC_URL}images/icons/flag_uk.png`} alt="UK"/>
                        </figure>
                        <div className="info">
                          <span className="name">Chris Charnely</span>
                          <span className="nation">Y6 Maths Coordinator / UK</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      )
      : (
        <section className="section section-bg-grey">
          <div className="frame-inner">
            <h3 className="title-section">Trusted by Educators</h3>
            <div className="speech-boxes-list">
              <ul className="grid-column">
                <li className="col">
                  <div className="speech-box">
                    <div className="inner">
                      <div className="message">
                        Very satisfied providing personalized learning for students with learning loss! <br/>I appreciate
                        that it offers more individualized weaknesses and feedback compared to IXL.
                      </div>
                      <div className="bottom">
                        <figure className="flag">
                          <img src={`${process.env.PUBLIC_URL}images/icons/flag_usa.png`} alt="USA"/>
                        </figure>
                        <div className="info">
                          <span className="name">Erin Hampton</span>
                          <span className="nation">6thGrade Math Teacher / USA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="col">
                  <div className="speech-box">
                    <div className="inner">
                      <div className="message">
                        The AI Level Test at the beginning of the semester is an excellent tool for assessing individual
                        student levels.
                      </div>
                      <div className="bottom">
                        <figure className="flag">
                          <img src={`${process.env.PUBLIC_URL}images/icons/flag_usa.png`} alt="USA"/>
                        </figure>
                        <div className="info">
                          <span className="name">Nichole Myers</span>
                          <span className="nation">6thGrade Math Teacher / USA</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="col">
                  <div className="speech-box">
                    <div className="inner">
                      <div className="message">
                        Love Mathpid's many sub topics within each major topic, and students find the notepad function
                        useful.
                      </div>
                      <div className="bottom">
                        <figure className="flag">
                          <img src={`${process.env.PUBLIC_URL}images/icons/flag_uk.png`} alt="UK"/>
                        </figure>
                        <div className="info">
                          <span className="name">Chris Charnely</span>
                          <span className="nation">Y6 Maths Coordinator / UK</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      )
  );
}

export default MathpidContent;