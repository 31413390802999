import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {useAppContext} from "../../App";
import {
  MathpidContactEn,
  MathpidContactKo,
  MathpidPolicyEn,
  MathpidPolicyKo,
  MathpidTermsEn,
  MathpidTermsKo
} from "./index";

function EtcPage(props) {
  const { langCd } = useAppContext();
  return (
    <Routes>
      <Route path="/contact" element={langCd === 'ko' ? <MathpidContactKo/> : <MathpidContactEn />} />
      <Route path="/terms" element={langCd === 'ko' ? <MathpidTermsKo /> : <MathpidTermsEn /> } />
      <Route path="/policy" element={langCd === 'ko' ? <MathpidPolicyKo /> : <MathpidPolicyEn /> } />
    </Routes>
  );
}

export default EtcPage;