import React from 'react';

function MathpidImpactReviews(props) {
  return (
    <main className="container">
      <section className="top-section-review">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">Reviews &amp; Testimonials</h2>
            <p className="sub-title">Real Experiences, Real Results</p>
            <figure className="img"><img src="../../../images/impact/img_review_main.png" alt="리뷰 이미지"/></figure>
            <p className="desc">Proven Results by Users! <br className="rwd-br-mo"/>Rapid Math Skill Advancement
              with <span className="text-primary">Mathpid</span></p>
            <p className="desc-more">Over just 12 weeks, students using Mathpid for only 34 minutes per week as a
              supplementary tool improved <br/>their math skills by an average of 7 levels, achieving a remarkable rate
              of 0.58 levels per week. <br/>US parents are thrilled with the results!</p>
          </div>
        </div>
      </section>

      <section className="section no-gutter">
        <div className="photo-scroll ui-photoscroll-slide swiper">
          <ul className="list swiper-wrapper">
            <li className="swiper-slide">
              <figure className="img"><img src="../../../images/impact/img_review_photo01.png" alt="리뷰 이미지"/></figure>
            </li>
            <li className="swiper-slide">
              <figure className="img"><img src="../../../images/impact/img_review_photo02.png" alt="리뷰 이미지"/></figure>
            </li>
            <li className="swiper-slide">
              <figure className="img"><img src="../../../images/impact/img_review_photo03.png" alt="리뷰 이미지"/></figure>
            </li>
            <li className="swiper-slide">
              <figure className="img"><img src="../../../images/impact/img_review_photo04.png" alt="리뷰 이미지"/></figure>
            </li>
          </ul>
        </div>
      </section>

      <section className="section section-bg-grey">
        <div className="frame-inner">
          <h3 className="title-section">Trusted by Educators</h3>
          <div className="speech-boxes-list">
            <ul className="grid-column">
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      I used Mathpid for my class of 50 students and loved how it automatically graded the students'
                      math exercises in just a minute, <strong>significantly reducing my workload.</strong>"
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_ph.png" alt="Philippines"/>
                      </figure>
                      <div className="info">
                        <span className="name">Teacher</span>
                        <span className="nation">Philippines</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      "I think it is absolutely useful and helpful to those that want to move their children from being
                      totally dependent <strong>to independent learners.</strong>"
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="USA"/>
                      </figure>
                      <div className="info">
                        <span className="name">Teacher and Home-educator</span>
                        <span className="nation">USA</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      I appreciate Mathpid's Structured learning approach and <strong>in-depth coverage of
                      topics.</strong> The four levels of multiplying decimals and the <strong>Notepad
                      feather</strong> are particularly valuable."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_uk.png" alt="UK"/>
                      </figure>
                      <div className="info">
                        <span className="name">Math Teacher</span>
                        <span className="nation">UK</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      Very satisfied providing <strong>personalized learning for students with learning loss!</strong> I
                      appreciate that it offers more individualized weaknesses and feedback compared to IXL."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="USA"/>
                      </figure>
                      <div className="info">
                        <span className="name">6th Grade Math Teacher</span>
                        <span className="nation">USA</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      The <strong>AI Level Test</strong> at the beginning of the semester is an <strong>excellent tool
                      for assessing</strong> individual student levels."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="USA"/>
                      </figure>
                      <div className="info">
                        <span className="name">6th Grade Math Teacher</span>
                        <span className="nation">USA</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      Love Mathpid's <strong>many sub topics</strong> within each major topic, and students find the
                      notepad function useful."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_uk.png" alt="UK"/>
                      </figure>
                      <div className="info">
                        <span className="name">Y6 Maths Coordinator</span>
                        <span className="nation">UK</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      After using Mathpid <strong>for just one month</strong>, my child has been answering math problems
                      correctly and quickly."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_kr.png" alt="South Korea"/>
                      </figure>
                      <div className="info">
                        <span className="name">Parents</span>
                        <span className="nation">South Korea</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      Mathpid allows my children to <strong>self-check their work with AI</strong> and learn at their
                      own page through its helpful videos, providing support even when I'm busy."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="USA"/>
                      </figure>
                      <div className="info">
                        <span className="name">Parents</span>
                        <span className="nation">South Korea</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      My son felt <strong>more comfortable and confident</strong> with his multiplication tables. Did
                      his homework faster and did better on tests."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_uk.png" alt="UK"/>
                      </figure>
                      <div className="info">
                        <span className="name">Parents</span>
                        <span className="nation">UK</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      I <strong>love Mathpid</strong> because I was able to achieve a score of <strong>78/80 in my final
                      math exam.</strong>"
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_in.png" alt="India"/>
                      </figure>
                      <div className="info">
                        <span className="name">Student</span>
                        <span className="nation">India</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      Best aap to <strong>improve speed and accuracy</strong> of questions solving. Thanks."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_in.png" alt="India"/>
                      </figure>
                      <div className="info">
                        <span className="name">Student</span>
                        <span className="nation">India</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      It is <strong>addictive</strong> and best for learning."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_kr.png" alt="Korea"/>
                      </figure>
                      <div className="info">
                        <span className="name">Student</span>
                        <span className="nation">South Korea</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MathpidImpactReviews;