import React from 'react';

function MathpidPolicy(props) {
  return (
    <main className="container">
      <section className="top-section-terms">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">Privacy policy</h2>
          </div>
        </div>
      </section>

      <section className="section no-gutter">
        <div className="terms-policy-wrap">
          <p>Dear Valued Customers, <br/>We would like to express our sincere gratitude for your continued use of
            Mathpid.&nbsp;We are writing to inform you about the upcoming implementation of our new Privacy Policy.</p>
          <p>[Modification Highlights]</p>
          <p>The categories of personal information collected and their specific purposes of use have been
            clarified.&nbsp;Updates have been made regarding consent for the collection of personal information.</p>
          <p>[Effective Date]</p>
          <p>June 24, 2024</p>
          <p>[Privacy Policy]</p>
          <p><a
            href="https://cdn.shopify.com/s/files/1/0649/1904/4348/files/Mathpid_Current_Privacy_Policy.pdf?v=1718778628">Current
            Policy (Effective from 2023.11.23) Detail</a></p>
          <p><a
            href="https://cdn.shopify.com/s/files/1/0649/1904/4348/files/Mathpid_Updated_Privacy_Policy_92b5270b-6ef2-4a0b-b236-8884e7fa5739.pdf?v=1719899074">Updated
            Policy (Effective from 2024.06.24) Detail</a></p>

          <hr/>

          <h3>[Mathpid - Privacy policy]</h3>
          <p>The MATHPID
            (“<span>Service</span>”<span>) that is operated by Woongjin Thinkbig Co., Ltd. (</span>“<span>Company</span>”<span>) is committed to make its data and personal data protection process transparent and is doing its best to protect the online personal data of the users of the Company</span>'<span>s website and application. This Privacy Policy (this </span>“<span>Privacy Policy</span>”<span>) explains the information collected by the Company through its application and website, how the information is used or disclosed, and the measures taken by the Company to protect the collected data.</span>
          </p>

          <h4>1. Collection of Personal Data and Purpose of Use</h4>
          <p>- To provide the Service to users
            (“<span>Parents</span>”<span> and </span>“<span>Child</span>”<span>);</span></p>
          <p>- To respond to user questions and to carry out requests;</p>
          <p>- To provide administrative information, such as information and the Terms and Conditions of the Service or
            amendment of the Terms and Conditions and policies, to users;</p>
          <p>- To send marketing information that the Company believes might be of interest to users;</p>
          <p>- To provide customized service to users;</p>
          <p>- For business purposes, such as data analysis, audit, monitoring for fraud, marketing and promotion,
            development of new products, improvement of service, identification of use patterns, and ascertaining
            marketing efficiency;</p>
          <p>- To resolve information leakage and other wrongful activities; and</p>
          <p>- To comply with legal procedures, to enforce the Terms and Conditions on the Use of MATHPID, for smooth
            operation of the Services, and to protect the Company'<span>s rights, your rights, and the rights of others, when it is judged necessary or appropriate.</span>
          </p>

          <h4>2. Types of Personal Data Collected and Purpose</h4>
          <p>The Company and the Company'<span>s service provider collect your information in the process of providing the Service to you. The information you directly provide the Company will be collected, and information that is created when you use the Service will be collected automatically.</span>
          </p>
          <p>Except as described in this Privacy Policy (2.3 Right to Information) in the context of succession of the
            rights and obligations of the Company by sale, merger, etc., the Company does not sell or lend your data to
            others.</p>
          <p>Users shall be categorized as student (children under the age of 16, hereinafter
            “<span>child</span>”<span> or </span>“<span>children</span>”<span>) or parent (a legal guardian of a </span>“<span>child</span>”<span> or </span>“<span>children,</span>”<span> having the responsibilities as a parent; hereinafter </span>“<span>Parent(s)</span>”<span>), and only </span>“<span>Parents</span>”<span> may subscribe to the Service.</span>
          </p>
          <p>Once the
            “<span>Parent</span>”<span> signs up for the Service or after signing up for the Service, the </span>“<span>Parent</span>”<span> may directly register the information of his/her </span>“<span>Child</span>”<span> and allow the </span>“<span>Child</span>”<span> to make use of the Service. By entering such information on his/her </span>“<span>Child,</span>”<span> the </span>“<span>Parent</span>”<span> is deemed as having consented to the provision of the </span>“<span>Child</span>'<span>s</span>”<span> information.</span>
          </p>
          <ul>
            <li>Service &gt; Registration as Student
              <p>A “<span>Parent</span>”<span> may consent to or refuse the provision of personal data at the time of Sign Up.</span>
              </p>
              <p>However, if the “<span>Parent</span>”<span> refuses to provide personal data, then normal use of the Service may be restricted.</span>
              </p>
              <p>Information provided by users and collected by the Company shall be kept and managed at a place
                operated by the Company:</p>
            </li>
            <li>Country and Place: Republic of Korea (AWS Asia Pacific (Seoul) Region)</li>
            <li>Information Collector: Woongjin Thinkbig</li>
            <li>Information Collected: Email of the subscriber, password of the subscriber, nickname of the student,
              user uploaded ‘<span>MATH CAMERA</span>'<span> images</span></li>
            <li>Time and Method of Transfer: Transfer via network at the time of use of the Service</li>
            <li>Email:&nbsp;<a href="mailto:mathpid@wjthinkbig.com">mathpid@wjthinkbig.com</a></li>
          </ul>

          <h4>2.1 Information Provided by Users to Create and Use Account</h4>
          <p>- When creating an account on MATHPID, the “<span>Parent</span>”<span> shall provide the email address and password to the Company. The password shall be kept under encryption, and the Company may send emails concerning service updates or marketing emails concerning its services that the Company believes the </span>“<span>Parent</span>”<span> may be interested in to the email address provided, and the Company may respond to users after being contacted by them.</span>
          </p>
          <p>The Company also provides the academic status of the “<span>Child</span>”<span> who has been registered by provision of such information, or provides additional services, such as customized services.</span>
          </p>
          <ul>
            <li>Service &gt; Main home &gt; Learning report &gt; Academic Progress of My Child</li>
            <li>Service &gt; Main home &gt; Learning report &gt; Analysis of Academic Status of My Child</li>
            <li>Service &gt; Main home &gt; Learning report &gt; Diagnosis Results on My Child</li>
          </ul>
          <p>- The nickname of the “<span>Child</span>”<span> registered by the </span>“<span>Parent</span>”<span> shall be provided to the Company. Information on the </span>“<span>Child</span>”<span> provided to the Company shall be used for the Company</span>'<span>s legal purposes, such as providing a customized academic experience and environment for the </span>“<span>Child</span>”<span> and improving the performance and design of the Service.</span>
          </p>
          <ul>
            <li>Service &gt; Main home</li>
            <li>Service &gt; Main home &gt; My class &gt; AI Level test</li>
            <li>Service &gt; Main home &gt; Learning</li>
          </ul>
          <p>- The Company collects only the information necessary for the Service. The Company does not sell or lend
            such information and does not create profiles for advertisements.</p>

          <h4>2.2 Automatically Collected Information/</h4>
          <p>- When you first use the Company'<span>s Service, the Company may automatically collect technical information, identifiers, and data concerning the method of your use of the Service</span>
          </p>
          <p>Information so collected may include information of the device(s) and browser(s) (device ID) used to access
            the Company'<span>s Service; IDFA; unique identifier; browser type and settings; device type and settings; operating system; mobile network information, including carrier name and application version information; IP address; crash report; system activities; requested dates and times; information concerning the interaction between the Service and the user; and position information (including GPS, IP address, device sensor data, Wi-Fi access point, etc.).</span>
          </p>
          <p>- The Company may also collect data created when you use the Service.</p>
          <p>Collected data may include study subjects and problems, correct answers, wrong answers, date and time of
            study, and frequency of study.</p>
          <p>- The Company also collects and saves information by making use of various technologies, such as cookies,
            pixel tags, browser web storages or application program data cache, and database and server logs.</p>
          <p>- All information collected from the
            Company'<span>s application and the Service are used for the Company</span>'<span>s legal purposes to provide a better user and learning experience to you and your students and to improve the performance and design of our Service. The Company may also use your email address to communicate with you concerning the Service and may also use your email address to respond to your queries.</span>
          </p>

          <h4>2.3. Right to Information</h4>
          <p>- Unless provided under this Privacy Data or otherwise permitted under applicable laws, the Company does
            not share with, disclose, or sell the information collected to any third parties, except for in the
            following instances:</p>
          <ul>
            <li>The Company uses a service provider to operate services such as cloud services and data analysis and
              processing. Such service provider uses the information only for the service(s) agreed with the Company and
              complies with rules and regulations on data protection that are equivalent to those in this Privacy
              Policy.
              <p>* Country and Place: Republic of Korea (AWS Asia Pacific (Seoul) Region)</p>
              <p>* Name of Company: Amazon Web Services, Inc.</p>
              <p>* Date and Method of Transfer: Transfer via network at the time of use of the Service</p>
              <p>* Representative for Personal Data Protection: General Agent Co., Ltd. (Representative: Kim
                Young-soo)</p>
              <p>* Address: 28, Saemunan-ro 5-ga-gil, Jongno-gu, Seoul</p>
              <p>* Tel. No.:&nbsp;<span><a href="tel:822-735-6888">822-735-6888</a></span></p>
              <p>* Email:&nbsp;<span><a href="mailto:AWS@generalagent.co.kr">AWS@generalagent.co.kr</a></span></p>
            </li>
            <li>If the Company goes through negotiations for a merger with or sale of the Company'<span>s assets to, in whole or in part, another company, or if the Company is involved in an emergency situation to protect the safety of an individual, the Company shall demand that the new owner continue to comply with this Privacy Policy, or the Company may provide notice to users and inform them of the opportunity to refuse the transfer thereof to the new owner by deleting their data.</span>
            </li>
          </ul>

          <h4>2.4 Rights of Data Owners to Review and Modify Personal Data or Withdraw Consent, and Methods to Exercise
            such Rights</h4>
          <p>- Upon any user'<span>s request to review or modify personal data or withdraw consent, the Company shall respond to such request without delay. Accordingly, any user may review or modify one</span>'<span>s own personal data or that of his/her child under the age of 16 and may request withdrawal of consent.</span>
          </p>
          <p>- Users seeking to review or amend their personal data can do so directly after having proved their
            identity by clicking on “<span>Change Personal Data (or Modify Personal Data)</span>”<span> and withdraw their consent by clicking on </span>“<span>Withdrawal of Membership.</span>”<span> Or, users may have appropriate measures promptly taken to do as the above by calling the representative number (Tel No.:&nbsp;</span><span><a
              href="tel:15771500">1577-1500</a></span>) or by contacting the Personal Data Manager in writing or by
            telephone.&nbsp;</p>
          <p>- If users request correction of any errors in their personal data, such personal data cannot be used or
            provided until such errors are corrected. If erroneous personal data has already been provided to a third
            party as set out under this Privacy Policy, the third party shall be notified of the corrected results so
            that the information may be corrected.</p>
          <p>- Any personal data terminated or deleted in accordance with a user'<span>s request shall be handled as set out under the </span>“<span>Period of Possession and Usage of Personal Data,</span>”<span> which was announced when personal data was collected, and shall not be reviewed or used for purposes other than the foregoing.</span>
          </p>
          <p>However, in the case of any of the following, the Company may restrict or refuse such request, and in such
            case, the Company shall promptly notify the user:</p>
          <p>If there are special provisions under applicable laws or if it is inevitable to comply with the obligations
            set out under applicable laws;</p>
          <p>If there is a risk of harming another person'<span>s life or body or unreasonably infringing on another person</span>'<span>s property or any other interest; or</span>
          </p>
          <p>If it is difficult to fulfill the agreement if personal data is not handled, such as inability to provide
            the Member with the Service, and the Member has not clearly indicated whether he/she wants to terminate the
            agreement.</p>

          <h4>3. Storage and Destruction of Personal Data</h4>
          <p>- Upon a user'<span>s request for deletion, after achievement of the purpose of the use of personal data, or after the end of the Period of Possession and Usage of Personal Data, the Company shall, as a principle, promptly destroy the personal data provided by the users.</span>
          </p>
          <p>However, if the Company has obtained separate consent from users concerning the Period of Possession of
            Personal Data or if the Company is under an obligation under applicable laws to retain such personal data
            for a certain period, the Company shall safely store the personal data by transferring them to a separate
            database (DB) or a different place of storage.</p>
          <p>- If consent is obtained from users concerning the Period of Possession of Personal Data when signing up
            for the Service, storage and destruction of personal data shall be as follows:</p>
          <p>If a user'<span>s personal data is collected pursuant to Article 50 of the Act on Promotion of Information and Communications Network Utilization and Information Protection, etc. to extend services concerning the same types of goods or to provide information on new services, the personal data shall be destroyed after they have been stored for the period (6 months after the termination date of the agreement) that the user had consented to when such personal data was collected.</span>
          </p>
          <p>- Period of Possession and Usage of Personal Data under other applicable laws is as follows:</p>
          <p>Act on the Consumer Protection in Electronic Commerce, etc.</p>
          <ul>
            <li>Provision records on withdrawal of agreement or subscription: 5 years&nbsp;</li>
            <li>Provision records on payment and goods, etc.: 5 years</li>
            <li>Records on handling consumer complaints or disputes: 3 years</li>
            <li>Records on labeling and advertisements: 5 months</li>
          </ul>
          <p>- Information automatically collected shall be stored indefinitely, unless otherwise requested, and such
            information shall be used for improvement of the Service, development of new products, and improvements in
            performance.</p>
          <p>- Procedure and method of destruction</p>
          <p>Personal data subject to destruction shall be destroyed after obtaining approval from the Personal Data
            Manager.</p>
          <p>Personal data in electronic file format shall be safely deleted in a way that cannot be restored or played,
            and paper-based personal data shall be destroyed by shredding or incineration.</p>

          <h4>4. Advertising and Payment</h4>
          <p>- There is no third party advertising on the MATHPID Service. Only promotions and advertising on
            information such as Service events, updates, and information that may be helpful for learning are posted on
            MATHPID, and personal data provided by users are not used or disclosed.</p>
          <p>- All information related to users' in-app product payments is managed by Google and not stored in our
            company.</p>

          <h4>5. Lawful Bases for Processing Personal Data</h4>
          <p>If you are an individual in the European Union (EU) or an EU citizen, we collect and process data about you
            only where we have legal bases for doing so under applicable EU laws. This means we collect and process your
            data only when:<br/>It is necessary for a legitimate interest (which is not overridden by your individual
            privacy interests), such as preventing fraud, improving the Service, and increasing the security of the
            Service and network infrastructure;</p>
          <ul>
            <li>You have consented to this collection and processing for a specific purpose;</li>
            <li>It is necessary to fulfil our contractual obligations;</li>
            <li>It is necessary to comply with a legal obligation.</li>
          </ul>
          <p>Where we rely on your consent to process your personal data, you have the right to withdraw or decline
            consent at any time. If you wish to withdraw your consent, please contact us using the information in the
            Contact for Individual Rights Requests section. Some examples of our legitimate interests and the data being
            processed include:<br/><br/></p>
          <ul>
            <li>Network and information security (IP address, Device ID)</li>
            <li>Customer Support and fraud prevention (name, email address)</li>
            <li>Improving our products and services (hardware information, activity logs)</li>
          </ul>
          <p>Where we rely on our legitimate interests to process your personal data, you have the right to object. If
            you have any questions about or need further information concerning the legal basis on which we collect and
            use your personal data, please contact us at&nbsp;<span><a
              href="mailto:mathpid@wjthinkbig.com">mathpid@wjthinkbig.com</a></span>.</p>

          <h4>6. Service for Personal Data-Related Civil Complaints</h4>
          <p>- The Company has designated the following persons as personal data controller and manager to protect
            users'<span> personal data and handle personal data-related complaints:</span></p>
          <ul>
            <li>Chief Privacy Officer: Lee Soo-jong, Head of the Management Assistance Division</li>
            <li>Personal Data Manager: Ahn Won-pil, Head of the Information Protection Team</li>
            <li>No.:&nbsp;<span><a href="tel:15771500">1577-1500</a></span></li>
          </ul>
          <p>- Any complaints that may arise in connection with personal data while using the MATHPID Service may be
            reported to the Personal Data Manager or the relevant department, and the Company shall promptly carry out
            sufficient measures regarding the matters reported by the relevant user.</p>
          <p>- If a user needs to report any other infringement on personal data or is in need of consultation, please
            contact any of the below agencies:</p>
          <ul>
            <li>Cybercrime Investigation Unit of the Supreme Public Prosecutors'<span> Office:&nbsp;</span><span><a
              href="http://spo.go.kr/">http://spo.go.kr</a></span>(Tel. No.: 1301)
            </li>
            <li>Cyber Safety Bureau of the National Police Agency:&nbsp;<span><a
              href="https://ecrm.cyber.go.kr/minwon/main">https://ecrm.cyber.go.kr/minwon/main</a></span>(Tel. No.: 182)
            </li>
            <li>Personal Information Dispute Mediation Committee:&nbsp;<span><a
              href="http://privacy.kisa.or.kr/">http://privacy.kisa.or.kr</a></span>(Tel. No.:&nbsp;<span><a
              href="tel:02-1833-6972">02-1833-6972</a></span>)
            </li>
          </ul>

          <h4>7. Data Security and Notice of Breac</h4>
          <p>- The Company does its best to protect the security of the collected personal data. The Company continues
            to endeavor to come up with appropriate physical, technical, and procedural safety measures to prevent
            unauthorized access, maintain data security, and appropriately use the collected data.</p>
          <ul>
            <li>The Company encrypts transmitted data.</li>
            <li>The Company keeps up to date on the Personal Information Protection Act and model cases and receives
              training on the importance of personal data protection from product design to service launch.
            </li>
            <li>The Company restricts employees from accessing data, except for those who need to access them for the
              operation of the Service.
            </li>
          </ul>
          <p>Although it may be impossible to prevent all potential security infringements, the Company continues to
            exert its best effort to provide reasonable security for the data processed and managed by the Company.</p>
          <p>If the Company perceives any unauthorized access to personally identifiable data and determines that such
            data may be used in a harmful way, the Company shall attempt to contact you at the email address provided to
            the Company or notify you through the Company'<span>s application or website.</span></p>
          <p>This Privacy Policy is effective as of November 23, 2023, and any amendment made hereto shall be notified
            to users by public notice on the Company's website (or individual notice).</p>

        </div>
      </section>
    </main>
  );
}

export default MathpidPolicy;