import React from 'react';

function MathpidImpact(props) {
  return (
    <main className="container">
      <section className="top-section-impact" lang="en">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">A distinctive math learning solution <br className="rwd-br-pc"/>by WoongjinThinkbig
              and leading educators</h2>
            <p className="desc">Give your child and students the gift of math confidence with a program designed to
              enhance<br className="rwd-br-pc"/> mathematical thinking and close learning gaps.</p>
            <p className="desc-more-sm">Developed with esteemed Professor Minhyung Kim, this solution strengthens
              foundational math skills, <br className="rwd-br-pc"/>addresses learning disparities, and builds confidence
              in math.</p>
          </div>
          <div className="from-consultant">
            <div className="consultant-info">
              <div className="message"><p className="txt">Basic skills in mathematics are analogous <br
                className="rwd-br-pc"/>to physical fitness in athletics.</p></div>
              <div className="info">
                <p className="name"><em>Minhyong Kim</em>(Consultant, <span className="text-primary">Mathpid</span>)</p>
                <p className="job">Professor of University of Edinburgh</p>
              </div>
            </div>
            <figure className="img"><img src="../../../images/impact/img_impact_main.png"/></figure>
          </div>
        </div>
      </section>

      <section className="section section-bg-blue">
        <div className="frame-inner">
          <div className="rwd-grid-column gap-32 align-center">
            <div className="col">
              <div className="grid-column impact-txt-content">
                <div className="col">
                  <h3 className="title">A UNESCO <br className="rwd-br-pc"/>recognized AI learning service</h3>
                </div>
                <div className="col sub">Mathpid, a trusted EdTech solution honored <br
                  className="rwd-br-pc"/>by <strong>UNESCO in 2024!</strong></div>
                <div className="col desc">This personalized math learning program is the result of over 40 years of
                  educational expertise from Woongjin ThinkBig, enhanced by advanced AI technology. Developed by our AI
                  educational lab, our AI engine and personalized learning system set a new benchmark for the future of
                  education.
                </div>
              </div>
            </div>
            <div className="col">
              <figure className="img"><img src="../../../images/impact/img_impact_content.png" alt="이미지"/></figure>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-bg-navy">
        <div className="frame-inner">
          <h3 className="title-section">Mathpid by the numbers</h3>
          <div className="by-number-content">
            <ul className="rwd-grid-column">
              <li className="col">
                <div className="grid-column align-center gap-20">
                  <div className="col img">
                    <i className="icon-impact-figure01"></i>
                    <div className="numbers">
                      <span className="num">30K+</span>Problems
                    </div>
                  </div>
                  <div className="col txt">Endless learning <br className="rwd-br-pc"/>possibilities with over <br/>30,000
                    math problems.
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="grid-column align-center gap-20">
                  <div className="col img">
                    <i className="icon-impact-figure02"></i>
                    <div className="numbers">
                      <span className="num">500+</span>Concept videos
                    </div>
                  </div>
                  <div className="col txt">5Master math easily and <br/>enjoyably with over <br className="rwd-br-pc"/>500
                    concept videos.
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="grid-column align-center gap-20">
                  <div className="col img">
                    <i className="icon-impact-figure03"></i>
                    <div className="numbers">
                      <span className="num">3.3M+</span>Global users
                    </div>
                  </div>
                  <div className="col txt">Chosen by 3.3 million users<br/> for fun and engaging <br
                    className="rwd-br-pc"/>math learning.
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="grid-column align-center gap-20">
                  <div className="col img">
                    <i className="icon-impact-figure04"></i>
                    <div className="numbers">
                      <span className="num">50M+</span>Problems solved
                    </div>
                  </div>
                  <div className="col txt">Personalized learning <br className="rwd-br-pc"/>powered by <br
                    className="rwd-br-mo"/>over 50 million <br className="rwd-br-pc"/>solved problems
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MathpidImpact;