import React from 'react';

function MathpidImpactFeatures(props) {
  return (
    <main className="container">
      <section className="top-section-features">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">AI-powered Math Learning Solution <br/>Designed for Pre-K to Grade 9!</h2>
            <p className="desc">Tailored for learners around the world!</p>
          </div>
        </div>
      </section>

      <div className="rwd-photo-area">
        <figure className="img"><img src="../../../images/impact/img_feature_main.png" alt=""/></figure>
      </div>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">1:1 Personalized Learning</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content01.png"
                                                alt="AI Level test"/></div>
                  <dl className="col txt">
                    <dt className="tit">AI Level test</dt>
                    <dd className="desc">With just 10 problems, our AI diagnoses your math level with over 95% accuracy
                      and reveals your math proficiency!
                    </dd>
                  </dl>
                </div>
              </li>
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content02.png" alt="My class"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">My class</dt>
                    <dd className="desc">Immerse yourself in a personalized learning journey tailored just for you,
                      offering the attention of a 1:1 tutor!
                    </dd>
                  </dl>
                </div>
              </li>
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content03.png"
                                                alt="Real-time Suggestion"/></div>
                  <dl className="col txt">
                    <dt className="tit">Real-time Suggestions</dt>
                    <dd className="desc">Mathpid's AI instantly identifies and suggests related problems, ensuring you
                      get comprehensive practice!
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">Various Learning Tools</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content04.png"
                                                alt="Step-by-Step Explanation Videos"/></div>
                  <dl className="col txt">
                    <dt className="tit">Step-by-Step Explanation Videos</dt>
                    <dd className="desc">Navigate math with ease! Bite-sized video lessons break down complex problems
                      for effortless understanding
                    </dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content05.png"
                                                alt="Hands-On Worksheet Workouts"/></div>
                  <dl className="col txt">
                    <dt className="tit">Hands-On Worksheet Workouts</dt>
                    <dd className="desc">Sharpen your skills with hands-on worksheet training, curated for comprehensive
                      mastery.
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">Master Tricky Problems</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content06.png"
                                                alt="Math Camera"/></div>
                  <dl className="col txt">
                    <dt className="tit">Math Camera</dt>
                    <dd className="desc">Facing a math hurdle? Handwrite or snap it, and our AI swiftly deciphers and
                      solves for you!
                    </dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content07.png"
                                                alt="Step-by-Step Explanation Videos"/></div>
                  <dl className="col txt">
                    <dt className="tit">Step-by-Step Explanation Videos</dt>
                    <dd className="desc">Afterwards, immerse in bite-sized videos. Beyond just answers, truly understand
                      and internalize the math behind it!
                    </dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content08.png"
                                                alt="Similar Problems"/></div>
                  <dl className="col txt">
                    <dt className="tit">Similar Problems</dt>
                    <dd className="desc">Want extended practice? Our AI swiftly suggests similar problems!</dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">For the Educators: <br/>Optimize with Mathpid</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content09.png"
                                                alt="Performance Reports"/></div>
                  <dl className="col txt">
                    <dt className="tit">Performance Reports</dt>
                    <dd className="desc">Our AI doesn't just analyze; it pinpoints. Get razor-sharp insights into
                      students' strengths and areas for growth.
                    </dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content10.png"
                                                alt="Math power"/></div>
                  <dl className="col txt">
                    <dt className="tit">Math power</dt>
                    <dd className="desc">Experience live visualization of student progress for tailor-made guidance.
                    </dd>
                  </dl>
                </div>
              </li>

              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content11.png" alt="학습 관리 시스템"/>
                  </div>
                  <dl className="col txt">
                    <dt className="tit">LMS Customization</dt>
                    <dd className="desc">Your curriculum, your rules. Design bespoke learning pathways with Mathpid's
                      LMS
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="frame-inner">
          <div className="features-content">
            <h3 className="title-section">Gamification: Ranking</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="grid-column">
                  <div className="col img"><img src="../../../images/impact/img_feature_content12.png"
                                                alt="Gamification: Ranking"/></div>
                  <dl className="col txt">
                    <dt className="tit blind">Gamification: Ranking</dt>
                    <dd className="desc">Unleash the competitive spirit! You can challenge yourself for the top spots in
                      real-time global rankings
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MathpidImpactFeatures;