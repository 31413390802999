import React, {useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import {useAppContext} from "../../App";
import {
  MathpidImpactEn,
  MathpidImpactFeaturesEn,
  MathpidImpactFeaturesKo,
  MathpidImpactKo, MathpidImpactReviewsEn,
  MathpidImpactReviewsKo
} from "./index";

function ImpactPage(props) {
  const { langCd } = useAppContext();

  useEffect(() => {
    var swiper = new window.Swiper('.ui-photoscroll-slide', {
      slidesPerView: 'auto',
      spaceBetween: 0,
      breakpoints: {
        1920: {
          touchRatio: 0,
        }
      },
    });
  }, []);

  return (
    <Routes>
      <Route path='/impact' element={langCd === 'ko' ? <MathpidImpactKo/> : <MathpidImpactEn />} />
      <Route path='/impact_features' element={langCd === 'ko' ? <MathpidImpactFeaturesKo/> : <MathpidImpactFeaturesEn />} />
      <Route path='/impact_reviews' element={langCd === 'ko' ? <MathpidImpactReviewsKo/> : <MathpidImpactReviewsEn />} />
    </Routes>
  );
}

export default ImpactPage;