import React, {useEffect} from 'react';
import { useNavigation } from "react-router-dom";
import MathpidContent from "../../../component/mathpid_content";
import {useAppContext} from "../../../App";

function MathpidMain(props) {
  const { handlePage } = useAppContext();
  return (
    <main className="container">
      <section className="main-top-section">
        <div className="main-visual bg-primary">
          <div className="frame-inner">
            <div className="grid-column">
              <div className="col-12 view-column">
                <div className="video-box">
                  <div className="video">
                    <iframe width="100%" height="100%"
                            src="https://www.youtube.com/embed/iQjqXkLo8l0?si=uzHN-Ta5axtvnxQT"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 copy-column">
                <div className="main-copy-area">
                  <div className="main-copyright">
                    <h2 className="title">수학에 대한 두려움을 자신감으로!</h2>
                    <p className="sub-copy-type1">AI 맞춤 학습으로 학생들의 <br className="rwd-br-mo"/>수학 자신감을 키우고, <br/>학급 전체의 학업
                      성취도를 높여보세요.</p>
                    <p className="sub-copy-type2">초중등 AI 연산 학습 솔루션</p>
                  </div>
                  <div className="main-btn">
                    <a href="https://class.mathpid.com/member/createAccount?type=lms" target="_blank"
                       className="btn-basic-md btn-point"><strong>지금 매쓰피드를 교실에서 만나보세요</strong></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="quick-links">
          <div className="frame-inner ui-quicklink-slide swiper">
            <ul className="quick-links-list swiper-wrapper">
              <li className="swiper-slide">
                <div className="quick-link-item">
                  <figure className="img">
                    <img src="../../../images/main/img_main_educators.png" alt="캐릭터이미지"/>
                  </figure>
                  <div className="grid-column">
                    <div className="col txt">
                      <strong>선생님용</strong>
                      <p>강력한 교육 솔루션</p>
                    </div>
                    <div className="col btn">
                      <a onClick={() => handlePage('/educators')} className="btn-basic-xs btn-primary"><span>더 알아보기</span></a>
                    </div>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="quick-link-item">
                  <figure className="img">
                    <img src="../../../images/main/img_main_learners.png" alt="캐릭터이미지"/>
                  </figure>
                  <div className="grid-column">
                    <div className="col txt">
                      <strong>학생용</strong>
                      <p>쉽고 재미있는 수학 학습</p>
                    </div>
                    <div className="col btn">
                      <a onClick={() => handlePage('/learners')} className="btn-basic-xs btn-primary"><span>더 알아보기</span></a>
                    </div>
                  </div>
                </div>
              </li>
              <li className="swiper-slide">
                <div className="quick-link-item">
                  <figure className="img">
                    <img src="../../../images/main/img_main_try.png" alt="캐릭터이미지"/>
                  </figure>
                  <div className="grid-column">
                    <div className="col txt">
                      <strong>Mathpid 체험</strong>
                      <p>글로벌 사용자 300만명</p>
                    </div>
                    <div className="col btn">
                      <a href="https://class.Mathpid.com" target="_blank" className="btn-basic-xs btn-primary"><span>더 알아보기</span></a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>

      <section className="main-section">
        <h3 className="blind">매쓰피드 소개</h3>
        <div className="main-introduce introduce-grid-wrap">
          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/main/img_main_intro01.png" alt="소개 이미지 1"/>
            </figure>
            <div className="text">
              <h4 className="head">학습 격차 해소를 위한 <br className="rwd-br-mo"/>맞춤형 학습 수업</h4>
              <p className="sub">10문제 내외 AI 레슨 테스트를 통해 학생 개개인의 강점과 취약점을 분석하고 맞춤형 학습을 제공합니다.</p>
              <p className="desc">실시간으로 학생의 이해도에 따라 문제 난이도를 자동으로 조절하기 때문에 취약 유형 문제를 제공하고 학습 격차를 줄이는데 도움을 줍니다.</p>
            </div>
          </div>

          <div className="introduce-grid-type2">
            <figure className="img">
              <img src="../../../images/main/img_main_intro02.png" alt="소개 이미지 2"/>
            </figure>
            <div className="text">
              <h4 className="head">학생 중심 수업을 지원하는 <br className="rwd-br-mo"/>학급 관리 시스템</h4>
              <p className="sub">학급 대시보드를 통해 학생들의 학습 진도와 성취도를 파악하고 간편하게 학급 관리할 수 있습니다.</p>
              <p className="desc">선생님의 업무 부담을 줄이고 학생 실력 맞춤형 숙제를 제공하여 특별 보충 학습이 필요한 학생을 효율적으로 관리할 수 있습니다.</p>
            </div>
          </div>

          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/main/img_main_intro03.png" alt="소개 이미지 3"/>
            </figure>
            <div className="text">
              <h4 className="head">학생들이 언제 어디서나 <br className="rwd-br-mo"/>학습 가능한 매쓰피드 앱</h4>
              <p className="sub">학교부터 집까지, 언제 어디서나 학습할 수 있기 때문에 연산실력 증진에 도움을 줍니다.</p>
              <p className="desc">모바일, 테블릿, 크롬북까지! 원하는 기기로 학생들이 자유롭게 학습할 수 있습니다.</p>
            </div>
          </div>
        </div>
      </section>

      <MathpidContent />

      <section className="section">
        <div className="frame-inner">
          <h3 className="title-section">교육자, 학부모, 전문가가 추천하는 매쓰피드</h3>
          <div className="awards-list">
            <ul className="grid-row">
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards01.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards02.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards03.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards04.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards05.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards06.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards07.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards08.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards09.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards10.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards11.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards12.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards13.png" alt="Awards"/></figure>
              </li>
              <li>
                <figure className="img"><img src="../../../images/logos/logo_awards14.png" alt="Awards"/></figure>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MathpidMain;