import React from 'react';

function MathpidLearners(props) {
  return (
    <main className="container">
      <section className="top-section-learner">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">Boost your child's math confidence <br/>in just 30 minutes a week!</h2>
            <p className="desc">Experience your child make amazing progress with a little daily practice</p>
          </div>
        </div>
      </section>

      <div className="photo-area">
        <figure className="img rwd-img-mo"><img src="../../../images/learners/img_learners_main01_m.png"
                                                alt="just 30 minutes a week with mathpid"/></figure>
        <figure className="img rwd-img-pc"><img src="../../../images/learners/img_learners_main01.png"
                                                alt="just 30 minutes a week with mathpid"/></figure>
      </div>

      <section className="main-section">
        <h3 className="blind">For Learners</h3>
        <div className="learners-introduce introduce-grid-wrap">
          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/learners/img_learners_intro01.png" alt="소개 이미지 1"/>
            </figure>
            <div className="text">
              <h4 className="head">See the difference in just 12 weeks!</h4>
              <p className="desc">If you're a parent looking to boost your child's math skills, try Mathpid. In a U.S.
                beta test, students who used Mathpid <br className="rwd-br-pc"/>for just 30 minutes a week improved
                their understanding of math topics to a level one grade higher within 12 weeks. <br
                  className="rwd-br-pc"/>All 20 participating students showed significant progress, with varying levels
                of individual achievement.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-bg-grey">
        <div className="frame-inner">
          <h3 className="title-section">Real stories from parents and children</h3>
          <p className="title-section-desc">See how effective Mathpid is through genuine feedback from real users.</p>
          <div className="speech-boxes-list">
            <ul className="grid-column">
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      After using Mathpid <strong>for just one month,</strong> my child has been answering math problems
                      correctly and quickly."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_kr.png" alt="Korea"/>
                      </figure>
                      <div className="info">
                        <span className="name">Parents</span>
                        <span className="nation">South Korea</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      Mathpid allows my children to <strong>self-check their work with AI</strong> and learn at their
                      own page through its <strong>helpful videos</strong>, providing support even when I'm busy."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="USA"/>
                      </figure>
                      <div className="info">
                        <span className="name">Parents</span>
                        <span className="nation">USA</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      My son felt <strong>more comfortable and confident</strong> with his multiplication tables. Did
                      his homework faster and did better on tests."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_uk.png" alt="UK"/>
                      </figure>
                      <div className="info">
                        <span className="name">Parents</span>
                        <span className="nation">UK</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      I <strong>love Mathpid</strong> because I was able to achieve a score of <strong>78/80 in my final
                      math exam.</strong>"
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_in.png" alt="India"/>
                      </figure>
                      <div className="info">
                        <span className="name">Student</span>
                        <span className="nation">India</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      Best app to <strong>improve speed and accuracy</strong> of questions solving. Thanks"
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_in.png" alt="India"/>
                      </figure>
                      <div className="info">
                        <span className="name">Student</span>
                        <span className="nation">India</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      It is <strong>addictive</strong> and best for learning."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_kr.png" alt="Korea"/>
                      </figure>
                      <div className="info">
                        <span className="name">Student</span>
                        <span className="nation">South Korea</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section section-bg-navy">
        <div className="frame-inner">
          <div className="learners-ai-list">
            <h3 className="title-section">AI tailored math challenges <br className="rwd-br-mo"/>just right for my
              child!</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="rwd-grid-column align-center gap-24">
                  <div className="col img"><i className="icon-learners-ai01"></i></div>
                  <div className="col txt">With 'My Class', the AI tutor identifies the ideal math level <br/>for your
                    child, allowing them to focus on areas that need improvement.
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="rwd-grid-column align-center gap-24">
                  <div className="col img"><i className="icon-learners-ai02"></i></div>
                  <div className="col txt">Snap a picture or draw a problem for instant solutions, <br/>practice similar
                    problems, and easily understand concepts through videos!
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section no-gutter">
        <h4 className="blind">다운로드</h4>
        <div className="related-links">
          <div className="related-links-contents">
            <p className="desc">From elementary to middle school, <br className="rwd-br-pc"/>Start Mathpid now to boost
              math scores!</p>
            <ul className="links-group">
              <li>
                <a href="https://play.google.com/store/apps/details?id=com.wjthinkbig.mathpid&hl=en&pli=1"
                   className="btn-related-link" target="_blank">
                  <img src="../../../images/learners/btn_googleplay.png" alt="Googleplay"/>
                </a>
              </li>
              <li>
                <a href="https://apps.apple.com/us/app/mathpid-ai-math-learning/id1606099580"
                   className="btn-related-link" target="_blank">
                  <img src="../../../images/learners/btn_appstore.png" alt="App store"/>
                </a>
              </li>
              <li>
                <a href="https://class.Mathpid.com" className="btn-related-link" target="_blank">
                  <img src="../../../images/learners/btn_classmathpid.png" alt="Mathpid"/>
                </a>
              </li>
            </ul>
          </div>
          <figure className="img rwd-img-mo">
            <img src="../../../images/learners/img_learners_link01_m.png" alt="Background image"/>
          </figure>
          <figure className="img rwd-img-pc">
            <img src="../../../images/learners/img_learners_link01.png" alt="Background image"/>
          </figure>
        </div>
      </section>
    </main>
  );
}

export default MathpidLearners;