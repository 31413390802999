import React from 'react';

function MathpidImpact(props) {
  return (
    <main className="container">
      <section className="top-section-impact" lang="ko">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">웅진씽크빅과 교육자가 설계한 <br className="rwd-br-pc"/>특별한 수학 학습 프로그램</h2>
            <p className="desc">아이들의 수학 성장을 위한 특별한 선물, 웅진씽크빅이 함께합니다. <br className="rwd-br-pc"/>수학의 기초를 탄탄하게 다져주고, 학습
              격차를 해소하고, 아이들의 수학에 대한 자신감을 키워줍니다.</p>
            <p className="desc-more-sm">수학 대중화에 앞장서는 김민형 교수와 함께, 아이들의 수학적 사고력을 키우고 학습 격차를 줄여주는 <br
              className="rwd-br-pc"/>특별한 수학 학습 프로그램을 개발했습니다. </p>
          </div>
          <div className="from-consultant">
            <div className="consultant-info">
              <div className="message"><p className="txt">Basic skills in mathematics are analogous <br
                className="rwd-br-pc"/>to physical fitness in athletics.</p></div>
              <div className="info">
                <p className="name"><em>Minhyong Kim</em>(Consultant, <span className="text-primary">Mathpid</span>)</p>
                <p className="job">Professor of University of Edinburgh</p>
              </div>
            </div>
            <figure className="img"><img src="../../../images/impact/img_impact_main.png"/></figure>
          </div>
        </div>
      </section>

      <section className="section section-bg-blue">
        <div className="frame-inner">
          <div className="rwd-grid-column gap-32 align-center">
            <div className="col">
              <div className="grid-column impact-txt-content">
                <div className="col">
                  <h3 className="title">유네스코가 인정한 AI 학습 서비스</h3>
                </div>
                <div className="col sub"><strong>2024년 유네스코</strong>가 선정한 우수 에듀테크, 매쓰피드!</div>
                <div className="col desc">웅진씽크빅의 50년 교육 노하우와 최첨단 AI 기술이 만나 탄생한 개인 맞춤형 수학 학습 프로그램입니다. 웅진씽크빅의 교육 연구진이 직접
                  개발한 AI 엔진과 개인화 맞춤 학습 시스템의 우수성을 인정받아 차세대 교육의 새로운 기준을 제시합니다.
                </div>
              </div>
            </div>
            <div className="col">
              <figure className="img"><img src="../../../images/impact/img_impact_content.png" alt="이미지"/></figure>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-bg-navy">
        <div className="frame-inner">
          <h3 className="title-section">숫자로 말하는 매쓰피드</h3>
          <div className="by-number-content">
            <ul className="rwd-grid-column">
              <li className="col">
                <div className="grid-column align-center gap-20">
                  <div className="col img">
                    <i className="icon-impact-figure01"></i>
                    <div className="numbers">
                      <span className="num">30K+</span>문제
                    </div>
                  </div>
                  <div className="col txt">3만개 이상의 문제로 <br/>무한한 학습 가능</div>
                </div>
              </li>
              <li className="col">
                <div className="grid-column align-center gap-20">
                  <div className="col img">
                    <i className="icon-impact-figure02"></i>
                    <div className="numbers">
                      <span className="num">500+</span>개념영상
                    </div>
                  </div>
                  <div className="col txt">500개 이상의 개념 영상으로 <br/>쉽고 재미있게 수학 마스터</div>
                </div>
              </li>
              <li className="col">
                <div className="grid-column align-center gap-20">
                  <div className="col img">
                    <i className="icon-impact-figure03"></i>
                    <div className="numbers">
                      <span className="num">3.3M+</span>학생
                    </div>
                  </div>
                  <div className="col txt">330만 명 학생들이 선택한 <br/>재미있는 기초 수학 학습</div>
                </div>
              </li>
              <li className="col">
                <div className="grid-column align-center gap-20">
                  <div className="col img">
                    <i className="icon-impact-figure04"></i>
                    <div className="numbers">
                      <span className="num">50M+</span>문제풀이
                    </div>
                  </div>
                  <div className="col txt">누적 5천만개 문제 풀이<br/>데이터 기반 맞춤형 학습</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MathpidImpact;