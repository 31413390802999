import React from 'react';

function MathpidTerms(props) {
  return (
    <main className="container">
      <section className="top-section-terms">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">Terms of service</h2>
          </div>
        </div>
      </section>

      <section className="section no-gutter">
        <div className="terms-policy-wrap">
          <h3>[Mathpid - TERMS AND CONDITIONS FOR THE USE]</h3>

          <h4>Article 1 - Purpose</h4>
          <p>The purpose of these Terms and Conditions ("<span>Terms and Conditions</span>"<span>) is to set out the rights, obligations, and responsibilities of Woongjin Thinkbig Co., Ltd. (</span>"<span>Company</span>"<span>) and its Members with respect to the use of the services provided by the Company via its MATHPID application (</span>"<span>MATHPID</span>"<span>).</span>
          </p>

          <h4>Article 2 - Definitions</h4>
          <ol>
            <li>"<span>Company</span>"<span> refers to a person engaged in economic activities concerned with the </span>"<span>content</span>"<span> industry and who provides content and services related thereto. Under these Terms and Conditions, it refers to Woongjin Thinkbig Co., Ltd.</span>
            </li>
            <li>"<span>MATHPID</span>"<span> refers to a mobile application that contains various services and benefits for Members, such as arithmetic lessons and management of various types of information utilizing AI and events in which Members can participate</span>
            </li>
            <li>"<span>Service(s)</span>"<span> refers to all services provided by the Company and that are accessible regardless of the type of wired or wireless communications device.</span>
            </li>
            <li>"<span>Member</span>"<span> refers to a person who uses </span>"<span>MATHPID</span>"<span> by installing and accessing it on his/her wired or wireless communications device. As a subscriber to </span>"<span>MATHPID</span>"<span> by providing his/her personal data to the Company, such Member was granted an ID and uses the MATHPID application services in accordance with these Terms and Services.</span>
            </li>
            <li>"<span>Member ID</span>"<span> (hereinafter referred to as </span>"<span>ID</span>"<span>) refers to the email address selected by the Member and approved by the Company for identification of the Member and for the Member</span>'<span>s use of the services.</span>
            </li>
            <li>"<span>Password</span>"<span> refers to a combination of texts, numbers, and special characters (at least 6 characters) determined by the Member to confirm that the Member corresponds to the authorized ID and to protect the Member</span>'<span>s personal data.</span>
            </li>
            <li>"<span>Membership Sign Up</span>"<span> refers to a person (or </span>"<span>Client</span>"<span>) who consents to these Terms and Conditions and enters into a usage agreement with the Company in order to make use of the MATHPID services. If a person signs up as a Member, that person becomes a Member of the MATHPID services.</span>
            </li>
            <li>"<span>Cancellation of Membership</span>"<span> refers to when a Member voluntarily and permanently terminates or renounces his/her legal relationship with MATHPID, including all rights and obligations that arose for having entered into these Terms and Conditions.</span>
            </li>
            <li>"Paid Services" refers to the Mathpid services that can be used after payment of the usage fee via a
              separate payment method provided by the company. The details of the Paid Services are elaborately listed
              on the payment instruction page.
            </li>
            <li>"Paid Member" means a member who has purchased the Paid Services.</li>
            <li>"Free Member" refers to a member who is not a Paid Member and who has been granted the status
              automatically afforded to all members upon membership registration.
            </li>
            <li>"Payment" means the act of a member paying a certain amount set by the company on the app store in order
              to use the Mathpid Paid Services.
            </li>
            <li>"Purchase" refers to the act of a member selecting the Paid Services they wish to use and making a
              payment through a payment method, in exchange for the service.
            </li>
          </ol>
          <p>Except for the definitions set out hereunder, the definition of the terms used in these Terms and
            Conditions shall be in accordance with standard business practices and applicable laws.</p>

          <h4>Article 3- Provision of Identity Information, etc</h4>
          <p>The Company shall post the contents of these Terms and Conditions, corporate name, name of its
            representative, address of its business place (including the address of the location that can handle
            consumer complaints), telephone number, fax number, email address, business registration number, mail order
            business number, and chief privacy officer on the start page of the MATHPID application services so that
            users may easily find this information. However, these Terms and Conditions may be viewed by users through a
            separate connecting page.</p>

          <h4>Article 4- Effect/Amendment of these Terms and Conditions</h4>
          <ol>
            <li>These Terms and Conditions shall become effective upon the consent of the Member who seeks to use the
              services. If a Member clicks on the "<span>I consent</span>"<span> button with respect to these Terms and Conditions, the Member shall be deemed to have sufficiently understood and agreed to the application of these Terms and Conditions.</span>
            </li>
            <li>The Company may amend these Terms and Conditions if there is need to reflect any changes or business
              method changes made to the MATHPID services (such as, addition of new services, functions, technology, or
              paid content, or deletion of existing services), if required under applicable laws or regulations or
              according to any security-related reasons, or to prevent malicious use of or harm to the MATHPID services.
            </li>
            <li>If the Company amends these Terms and Conditions, the Company shall specify the effective date of the
              amendment and the reason(s) thereof and announce them on the start page of MATHPID 15 days prior to the
              effective date until the day before the effective date. However, if these Terms and Conditions are amended
              in a way that is disadvantageous to the Members, such amendment shall be notified by providing at least a
              30-day grace period.
            </li>
            <li>If the Company amends these Terms and Conditions, the amended Terms and Conditions shall apply to
              agreements that are executed after the effective date of the amended Terms and Conditions and shall apply
              retroactively to the Members after public notice thereof and a sufficient grace period. If a Member is
              notified that if he/she fails to express his/her refusal to such amendment by the effective/enforcement
              date, then he/she will be deemed to have agreed to the amendment, but the Member does not explicitly
              express his/her refusal to the amendment, then the Company shall deem that the Member has agreed to the
              amendment of these Terms and Conditions. A Member who has expressed his/her refusal to the
              amended/modified Terms and Conditions may choose between termination of the agreement or Cancellation of
              Membership.
            </li>
            <li>Generally, these Terms and Conditions shall be effective from the day the Member agrees to them to
              Member'<span>s Cancellation of Membership. However, some provisions of these Terms and Conditions may be effectively applied after the Member</span>'<span>s Cancellation of Membership.</span>
            </li>
            <li>These Terms and Conditions shall apply together with the operating terms of each service (i.e., Privacy
              Policy) provided by the Company. Any matters that are not provided hereunder and the interpretation of
              these Terms and Services shall be in accordance with the Act on the Consumer Protection in Electronic
              Commerce, Act on the Regulation of Terms and Conditions of the Republic of Korea
              ("<span>Korea</span>"<span>), and Guidelines for Consumer Protection in Electronic Commerce, etc., determined by the Korea Fair Trade Commission, and any other laws or business practices, regardless of any international business.</span>
            </li>
          </ol>

          <h4>Article 5 - Membership Sign Up</h4>
          <ol>
            <li>Membership Sign Up is completed by the Company'<span>s approval of the application for Membership Sign Up submitted by a user who has consented to the contents of these Terms and Conditions.</span>
            </li>
            <li>The following items must be filled out in the application form for Membership sign-up. Items (1) and (2)
              are mandatory items, and the remaining items are optional.
              <ol>
                <li>Email address</li>
                <li>Information on child/children (name(s) or nickname(s), date of birth)</li>
                <li>Information required to participate in events (name, contact information, address)</li>
                <li>Consent to Collection, Provision, and Use of Personal Data</li>
                <li>Any other matters that the Company deems necessary shall require separate consent.</li>
              </ol>
            </li>
            <li>Generally, the Company shall approve the Membership Sign Up with respect to the above user'<span>s application. However, if any of the following applies to the application for Membership Sign Up, the Company may not approve such application:</span>
              <ol>
                <li>If the application is submitted under a false name or the name of another person</li>
                <li>If information on the application is falsely stated</li>
                <li>If the application is filed for the purpose of undermining social stability, order, or public
                  morals
                </li>
                <li>If the applicant seeks to use the services for illegal purposes</li>
                <li>If the applicant seeks to use the services for for-profit purposes</li>
                <li>If the application lacks any other registration/application requirements determined by the Company
                </li>
                <li>If the applicant has previously lost his/her membership under these Terms and Services</li>
                <li>If the application has been filed in breach of any other regulations</li>
              </ol>
            </li>
            <li>The Company may postpone approval of the application if there is no capacity available in connection
              with the services or if there are technical or business-related issues.
            </li>
            <li>If the Company does not approve or postpone approval of the application under Clauses 3 or 4 above, the
              Company must notify such fact to the applicant unless the Company is unable to notify the applicant due to
              reasons that are not attributable to the Company.
            </li>
            <li>The Membership sign-up agreement goes into effect when the
              Company'<span>s approval reaches the user.</span></li>
          </ol>

          <h4>Article 6 - Special Provisions on Membership Sign-Up of Minors</h4>
          <ol>
            <li>Any user under the age of 14 must apply for Membership sign-up after obtaining sufficient understanding
              of the purpose of collection and use of personal data and consent from his/her legal guardian, such as a
              parent, and must provide his/her personal data.
            </li>
            <li>The Company may cancel or withhold approval for the application of any user under the age of 14 who does
              not obtain consent from his/her legal guardian, such as a parent.
            </li>
            <li>The legal guardian, such as a parent, of any user under the age of 14 may request to review, correct, or
              renew the personal data of the relevant child or withdraw consent for the Membership Sign Up, and in such
              case, the Company must carry out necessary measures without delay. In this case, the Company may request
              the submission of confirmation documents, such as documents proving the legal guardian'<span>s legal guardianship over the child.</span>
            </li>
          </ol>

          <h4>Article 7 - Amendment of Member Data</h4>
          <ol>
            <li>Any Member may view or amend his/her personal data at any time by 1:1 inquiry or via My Page. (Change of
              email address, password, etc possible)
            </li>
            <li>If any information given at the time of Membership Sign Up has changed, the Member must notify the
              Company of such changes by editing online or via email or other method.
            </li>
            <li>The Company shall not be responsible for any disadvantage that may arise due to the Member'<span>s failure to notify the Company of the changes mentioned under Clause 2 above.</span>
            </li>
          </ol>

          <h4>Article 8 - Obligations to Manage the Member's ID and Password</h4>
          <ol>
            <li>The obligation to manage the ID and password of a Member is borne by that Member, and the Member may not
              allow third party use of his/her ID and password.
            </li>
            <li>If a Member learns that his/her ID or password has been stolen or used by a third party, the Member
              shall promptly notify the Company and comply with the instructions given by the Company.
            </li>
            <li>The Company shall not be responsible for any disadvantage that may arise due to the Member'<span>s failure to notify the Company of the situation set out under Clause 2 above or if notice was given, the Member</span>'<span>s failure to comply with the instructions given by the Company after the Member had notified the Company.</span>
            </li>
          </ol>

          <h4>Article 9 - Notice to Members</h4>
          <ol>
            <li>If the Company sends out a notice to the Members, it may be sent to the email addresses designated by
              the Members.
            </li>
            <li>In the case of notice to all Members, the Company may post the notice on the Company'<span>s message board for at least seven (7) days in lieu of the notice set out under Clause 1 above. However, any notices concerning matters having material effect on a Member</span>'<span>s own transaction may be given individually.</span>
            </li>
          </ol>

          <h4>Article 10 - Cancellation of Membership, Loss of Qualification, etc.</h4>
          <ol>
            <li>Any Member may request Cancellation of Membership to the Company at any time, and the Company shall
              promptly process the cancellation. However, as of the date of cancellation, a Member who has cancelled
              his/her membership may not sign up for membership again under the same ID.
            </li>
            <li>
              If any of the following applies to a Member, the Company may restrict or suspend the Member'<span>s membership qualification:</span>
              <ol>
                <li>If the Member filled in false information that violates the agreement at the time of application for
                  membership;
                </li>
                <li>If the Member threatens the order of e-commerce by interfering with another person'<span>s use of the Company</span>'<span>s services or stealing the information of another person;</span>
                </li>
                <li>If by taking advantage of the Company, the Member commits any act prohibited under applicable laws
                  or these Terms and Conditions or commits any act that goes against public morals; or
                </li>
                <li>If the Member breaches any other obligations under these Terms and Conditions.</li>
              </ol>
            </li>
            <li>If after the Company restricts or suspends a Member'<span>s membership qualification, the Member commits the same act more than two times or the cause of the restriction on or suspension of membership qualification is not corrected within 30 days, the Company may revoke the Member</span>'<span>s membership qualification.</span>
            </li>
            <li>If the Company revokes the membership qualification of a Member, the Member'<span>s member registration shall be cancelled, and in such case, the Company shall notify the Member of the cancellation of member registration and provide the Member with an opportunity to explain before such cancellation is carried out.</span>
            </li>
            <li>In the case of Clause 2 above, the Company may claim damages against the Member.</li>
          </ol>

          <h4>Article 11 - Protection and Management of Personal Data</h4>
          <ol>
            <li>The Company may collect personal data from Members as needed to provide services to them.</li>
            <li>When the Company collects or uses the personal data of the Members, the Company shall notify them of the
              purpose thereof and obtain their consent thereto.
            </li>
            <li>The Company may not use the collected personal data for any other purpose, and if a new purpose arises
              or if such personal data is to be provided to a third party, the Company shall notify the relevant Members
              of such new purpose or provision to a third party in the use/provision stage and obtain their consent
              thereto; provided, that, if other provisions are set in the relevant laws and regulations, there are
              exceptions.
            </li>
            <li>A Member may at any time request to view or correct his/her personal data held by the Company, and the
              Company is obligated to promptly carry out necessary measures with regard to such request. If a Member
              requests correction of an error, the Member shall not use his/her personal data until that error is
              corrected.
            </li>
            <li>The Company must limit the number of people that handle Members'<span> personal data to a minimum to protect personal data, and the Company shall be fully responsible for any damage caused to the Members due to loss, theft, leakage, provision to any third party without consent, modification, etc. of Members</span>'<span> personal data, including credit card or bank account information.</span>
            </li>
            <li>The Company or any third party that is provided with personal data from the Company shall promptly
              destroy the relevant personal data once it achieves the purpose of such personal data collection or
              purpose of such provision.
            </li>
            <li>The Company shall not pre-select the box for consent to the Collection, Use, and Provision of Personal
              Data. However, if a Member refuses to consent to the Collection, Use, and Provision of Personal Data, the
              Member may be partially restricted from using MATHPID services.
            </li>
            <li>If a Member'<span>s personal data is leaked due to fault attributable to the Member and not to that of the Company, the Company shall not be responsible for such leakage.</span>
            </li>
            <li>Any other details regarding the handling of personal data, including the provision of personal data, are
              disclosed in detail under the Company'<span>s </span>"<span>Privacy Policy,</span>"<span> which requires separate consent from these Terms and Conditions.</span>
            </li>
          </ol>

          <h4>Article 12 - Obligation of Members</h4>
          <ol>
            <li>A Members shall comply with applicable laws, the regulations under these Terms and Conditions, the use
              guide, and any precautions regarding the service that were posted or notified to the Member and shall not
              carry out any other acts that interfere with the business of the Company.
            </li>
            <li>A Member must comply with the use restrictions for the service posted by category or separately noticed
              by the Company.
            </li>
            <li>Without obtaining the prior approval of the Company, a Member may not carry out any sales activities or
              any other for-profit activities by using the services, and the Company shall not be responsible for any
              results of the sales activities carried out by the Member or any sales activities in breach of these Terms
              and Conditions. If the Company suffers any damage due to such sales activities, the Member shall indemnify
              the Company for such damage.
            </li>
            <li>Without the explicit consent of the Company, a Member cannot assign or give the right to use the
              services and any other status under the usage agreement, and cannot provide such right or status as
              security.
            </li>
            <li>
              A Member may not carry out any of the following acts in relation to the use of services:
              <ol>
                <li>Provide false data when applying or amending existing data;</li>
                <li>Steal the data of others;</li>
                <li>Change the data posted by the Company;</li>
                <li>Send or post any information (including computer program) prohibited by the Company;</li>
                <li>Infringe on any intellectual property right, including copyright, of the Company or a third party;
                </li>
                <li>Any act that damages the reputation of or interferes with the business of the Company or a third
                  party;
                </li>
                <li>Any act of disclosing or posting obscene or violent speech or writing, videos, audio recordings or
                  any information that goes against public morals on the Company'<span>s website;</span></li>
                <li>Any illegal or unfair act; or</li>
                <li>Any other act that goes against applicable laws.</li>
              </ol>
            </li>
            <li>A Member shall comply with the provisions and precautions set out under these Terms and Conditions, and
              if the Member violates such provisions and precautions more than two times or fails to correct such breach
              within 30 days, the Company may revoke the Member'<span>s member qualification.</span></li>
            <li>If the Company revokes the member qualification of a Member, the Member'<span>s member registration shall be cancelled, and in such case, the Company shall notify the Member of such cancellation of member registration and provide the Member with an opportunity to explain before such cancellation is carried out.</span>
            </li>
          </ol>

          <h4>Article 13 - Establishment of a Service Usage Agreement and Limitation on Acceptance</h4>
          <ol>
            <li>A Service Usage Agreement shall be established by a Member'<span>s expression of his/her consent to these Terms and Conditions (by selecting consent) when applying for membership and the Company</span>'<span>s acceptance of the Member</span>'<span>s application.</span>
            </li>
            <li>Prior to execution of the Service Usage Agreement, a Member shall read and become well acquainted with
              the terms set out by the Company concerning the services under these Terms and Conditions to enter into
              the transaction without any misunderstandings.
            </li>
            <li>The Service Usage Agreement between the Company and a Member shall be established when the
              Company'<span>s acceptance of the Member</span>'<span>s application is received by the Member.</span></li>
            <li>If any grounds set out under Article 13 (Limitation on Acceptance) of these Terms and Conditions occur,
              the Company may refuse to accept the application or postpone acceptance of an application for a certain
              period until the grounds for limitation are resolved.
            </li>
            <li>The Company may collect the minimum amount of information needed for the Member to make use of the
              services in addition to the matters set out under Article 13 (Establishment of Service Usage Agreement and
              Limitation on Acceptance). The Member shall sincerely inform the Company of the matters the Company has
              inquired of the Member. In such case, the Company shall use and manage the information collected from the
              Member in accordance with the laws on the promotion of use and information protection set out in these
              Terms and Conditions, the Privacy Policy, and the Act on Promotion of Information and Communications
              Network Utilization and Information Protection.
            </li>
          </ol>

          <h4>Article 14 - Payment Methods</h4>
          <ol>
            <li>The payment methods available for members to pay for the Paid Services are as follows:</li>
            <li>App Store (Apple App Store/Google Play Store) payment methods.</li>
            <li>Through the payment for Paid Services, members are entitled to the following:</li>
            <li>Subscription to and usage of Paid Services.</li>
            <li>In the case of purchasing Paid Services (hereinafter collectively referred to as "Products"), all
              payment-related inquiries, issues, or complaints shall be handled in accordance with the terms and
              conditions of the payment service, such as the App Store utilized by you, and the company bears no
              responsibility nor any legal liability in this regard.
            </li>
          </ol>

          <h4>Article 15 - Article 15 (Paid Services and Paid Members)</h4>
          <ol>
            <li>Paid Services begin from the date of payment by the member, and if the service cannot be started due to
              technical reasons of the company or other circumstances, members will be notified in advance in the manner
              set forth in Article 4 (Effectiveness/Modification of Terms and Conditions).
            </li>
            <li>The company provides the following Paid Services; the details of these services are subject to change or
              addition depending on the company's circumstances and all other conditions. The contents of each Paid
              Service are clearly indicated to members on the Paid Services purchase page and customer service center
              (FAQ):

              <p>① Classification according to the usage period of the payment service:</p>
              <ul>
                <li>Individual Purchase Products: Products that can be paid for and used by "members" for specific
                  items.
                </li>
                <li>Subscription Products: Products that "members" can pay for and use for a certain period.</li>
              </ul>


              <p>② Individual &amp; Subscription Product Policy:</p>
              <ul>
                <li>Individual Purchase Products are processed only through App Store payments, and policies regarding
                  refunds after purchase are subject to each App Store's refund policy.
                </li>
              </ul>

              <p>③ Liability for Third-Party Payments:</p>
              <ul>
                <li>Except where a Product is purchased due to the unauthorized disclosure of your personal information
                  caused by the company's intentional misconduct or gross negligence, members bear all liability for
                  claims related to the member's account.
                </li>
                <li>The company is not responsible for any third-party payments, including those made by a third party
                  using your name or personal information due to your failure to use or careless exposure of the
                  password setting function of your device and App Store.
                </li>
                <li>iii. Members should take care to prevent acquaintances, children, or family from purchasing Products
                  using your device. Even if acquaintances, children, or family purchase Products without your consent
                  or contrary to your intention, except where otherwise provided by applicable laws, you bear the
                  responsibility for payment, and the company bears no responsibility.
                </li>
              </ul>

              <p>④ Refunds:</p>
              <ul>
                <li>Unless otherwise specified in these Terms or stipulated by law, all Products, including the
                  following, are non-refundable under any circumstances:
                  <ol>
                    <li>Products held by users whose service has been suspended due to violating these Terms or engaging
                      in illegal activities.
                    </li>
                    <li>Products that have been used either in whole or in part (such as partially used Subscription
                      Products).
                    </li>
                    <li>Products that have been purchased more than seven days ago.</li>
                    <li>Products of users who have withdrawn from the service.</li>
                    <li>Products paid for by a third party due to the loss or theft of the device, or user negligence.
                    </li>
                  </ol>
                </li>
                <li>Refunds for all Products allowed by law in the member's country of residence will be processed and
                  limited in accordance with the refund regulations of the App Store used for the purchase of the
                  Products.
                </li>
              </ul>
            </li>
          </ol>

          <h4>Article 16 - Suspension and Discontinuation of Paid Services</h4>
          <ol>
            <li>The company generally provides Paid Services 24 hours a day, 365 days a year.</li>
            <li>The company may limit, suspend, or discontinue all or part of the Paid Services during maintenance and
              repairs to improve the service. In this case, the company will give prior notice to the member of the
              reason for the suspension and the duration of the suspension as soon as possible, through the method set
              forth in Article 4 (Effect/Modification of the Terms and Conditions).
            </li>
          </ol>

          <h4>Article 17 - Obligations of the Members, the Company's Actions for Any Breaches of the Obligations</h4>
          <ol>
            <li>When making use of the services provided by the Company, Members shall comply with the applicable laws,
              Terms and Conditions, detailed guidelines on use, information on the use of services, and precautions
              posted on the Company'<span>s website, and notices notified to the Members by the Company with respect to the use of its services, and shall not damage the reputation of the Company or any other person or carry out any acts that interfere with the business activities of the Company, such as operation of services.</span>
            </li>
            <li>Members are responsible for managing their own ID and password and shall not allow any other person to
              use their ID or password.
            </li>
            <li>Unless permitted by the Company in advance, Members shall not use the services for any for-profit
              purposes, such as sales activities, or carry out any acts prohibited under these Terms and Conditions or
              carry out any acts using the services beyond their allowed scope.
            </li>
            <li>Members shall not carry out any illegal acts or any other acts that go against social customs and any
              other social order when using the services.
            </li>
            <li>Without prior approval from the Company, Member shall not use any information obtained by using the
              services for any purposes other than the use of the service.
            </li>
            <li>Members shall use the services provided by the Company within the scope of use set out by the Company
              and shall not be allowed to use the services for any for-profit purpose, such as playing the services at a
              place of business or store. In addition, Members shall not illegally distribute or share any videos and
              audio content that have been recorded or downloaded from the services, and the pertinent Members shall be
              held responsible for any issues that may arise due to a breach of the foregoing. (Such act is an
              infringement on the copyright of a third party in Korea or abroad, and it includes any act that infringes
              on the copyright of a third party by accessing MATHPID either by the user'<span>s intent or through means and methods to deceive the Company even though the Company has taken infringement prevention measures (technical measures) to prevent the infringement on its copyright by others by blocking certain IP addresses, etc.)</span>
            </li>
            <li>If any of Clauses 1 through 6 are committed by a Member, the Company may suspend the provision of
              services to that Member for one (1) month after notifying the Member of such breaches of obligations and
              may rescind or terminate the Service Usage Agreement if the same act of breach reoccurs. The
              Company'<span>s expression of its intent under this Clause becomes effective on the day such expression of intent is received by the Member. The Member may file any objection against the rescission/termination and usage suspension imposed by the Company in accordance with the procedures set out by the Company. In this case, if the Member proves that he/she was not at fault or had no such intent, or the Company finds the Member</span>'<span>s objection reasonable, the usage period shall be extended for the period that the Company suspended service.</span>
            </li>
            <li>Any Member who has not provided true and correct information in relation to the use of the services
              cannot enjoy legal protection and may be restricted from using the services.
            </li>
            <li>If any minor (as defined under the Civil Code of Korea) seeks to use the services of the Company, the
              minor or his/her legal representative may cancel the agreement if the minor fails to obtain the consent of
              his/her legal guardian (parent) or ratification after signing the agreement. When a minor under the age of
              14 provides his/her information to use the services, the minor'<span>s legal representative must approve the provision of information.</span>
            </li>
          </ol>

          <h4>Article 18 - Advertisements</h4>
          <ol>
            <li>MATHPID may be connected to other applications by hyperlink (hyperlink subjects include texts, drawings,
              and videos).
            </li>
            <li>The Company may post commercial advertisements on the MATHPID page or send such commercial
              advertisements by email, DM (Direct Message), push notification, etc. to individual Members to procure
              funds to smoothly provide the best possible MATHPID services to the Members; provided, that, commercial
              advertisements will no longer be sent if a Member clearly expresses his/her intent to opt out of such
              commercial advertisement.
            </li>
            <li>The Company shall not be responsible for any damages caused by the Members'<span> direct participation in an advertiser</span>'<span>s promotion activities through the MATHPID services.</span>
            </li>
          </ol>

          <h4>Article 19 - Indemnification</h4>
          <ol>
            <li>If any content included in the services provided by the Company is damaged or deleted due to a defect in
              the Company'<span>s services and a Member suffers damages in using the services as a result, the Company shall compensate the Member by restoring the relevant content. If any damage occurs to the Member, the Company shall indemnify the Member for only damages that have actually occurred. However, the Company shall not be held responsible for any damage caused to the Member that is not due to the intent or fault of the Company. If any damages occur to the Member due to fault attributable to the Company but not provided for under these Terms and Conditions, the Company follows the related regulations of the </span>‘<span>Guidelines on the Protection of Content Users</span>'<span> and other commercial practices regarding the Company</span>'<span>s responsibility to indemnify the Member.</span>
            </li>
            <li>If a Client is restricted from using the services or the Member'<span>s usage agreement is terminated based on the provisions concerned with restriction on usage under these Terms and Conditions, the Client loses all rights to use the digital content he/she possesses, and the Company shall not be obligated to return the purchase price paid by the Client for such content.</span>
            </li>
            <li>If any damage is caused to the Company due to a Member'<span>s breach of the above obligations or if a Member has caused damage to the Company by using the services, the Member shall indemnify the Company for such damage.</span>
            </li>
          </ol>

          <h4>Article 20 - Exemption</h4>
          <ol>
            <li>The Company shall not be responsible for any damages caused to a Member or third party due to any of the
              following causes:
              <ol>
                <li>If the Company cannot provide the services due to a natural disaster or any similar event of force
                  majeure;
                </li>
                <li>If the Member has been negligent in managing his/her ID or password;</li>
                <li>If the services are unusable due to disruption occurred to the public communications network, which
                  is outside the control and management of the Company; or
                </li>
                <li>If any damage has been caused by any disruption of communications services for which the Company is
                  not at fault.
                </li>
              </ol>
            </li>
            <li>The Company shall not be responsible for a Member'<span>s failure to obtain or loss of the benefits the Member had expected or for any damage occurring to the Member from his/her selection or use of the posts provided in the services.</span>
            </li>
            <li>With regard to the content of the reviews, ratings, information, material, etc. posted by a Member on
              MATHPID, the Member shall be held responsible for the credibility or accuracy thereof, and the Company
              shall not be responsible for any damage that occurs to Members or any third parties due to inaccuracy or
              falsity in such content.
            </li>
            <li>The Company shall not be obligated to become involved in any disputes that arise between Members or
              between a Member and a third party in relation to the services and shall not be held responsible for
              indemnifying for the damages caused by such dispute if the Company is not at fault for the same.
            </li>
          </ol>

          <h4>Article 21 - Dispute Resolution</h4>
          <ol>
            <li>The Company operates a customer service center (Tel No.:&nbsp;<span><a href="tel:15771500">1577-1500</a></span>),
              an office that handles compensation for damages, to receive legitimate opinions and complaints and to
              provide compensation for any harm or damages suffered by Members.
            </li>
            <li>These Terms and Conditions are governed by the laws of the Republic of Korea, regardless of its conflict
              of laws rules. If a dispute, controversy, or difference in opinion arising in relation to the use of the
              MATHPID services is not resolved amicably between the Member and the Company, then the courts pursuant to
              the Civil Procedure Act of Korea shall have competent jurisdiction regarding such dispute.
            </li>
            <li>In the case of Members with addresses or residing overseas, notwithstanding Clause 2 above, any lawsuit
              that involves a dispute between the Company and a Member shall be subject to the competent jurisdiction of
              the Seoul Central District Court of the Republic of Korea.
            </li>
            <li>The Company may provide an English version of these Terms and Conditions to help Members better
              understand them, but in the case of any conflicts between the English version and the Korean version of
              these Terms and Conditions, the Korean version shall prevail.
            </li>
          </ol>

          <p><u>[Effective Date] These Terms and Conditions are effective as of November 23, 2023.</u></p>
        </div>
      </section>


    </main>
  );
}

export default MathpidTerms;