import React from 'react';
import MathpidContent from "../../../component/mathpid_content";

function MathpidEducators(props) {
  return (
    <main className="container">
      <section className="top-section-educator">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">Helpful and accurate teacher's tool <br/>to bridge learning gaps, <br/>For Pre-K to
              Grade 9</h2>
            <p className="desc">AI-powered personalized math learning solution and <br/><strong>LMS(Learning Management
              System)</strong> designed for teachers</p>
          </div>
        </div>
      </section>

      <section className="section rwd-gutter">
        <div className="trial-box">
          <h3 className="title">Start Your <span className="text-point">30-Day Free</span> <br className="rwd-br-mo"/>Trial
            Now</h3>
          <ul className="trial-list">
            <li>
              <figure className="img"><img src="../../../images/educators/img_educators_trial01.png" alt="img"/>
              </figure>
              <p className="txt"><strong>First,</strong><span>Apply for a Free Trial</span></p>
            </li>
            <li>
              <figure className="img"><img src="../../../images/educators/img_educators_trial02.png" alt="img"/>
              </figure>
              <p className="txt"><strong>For Teacher,</strong><span>Access the Dashboard(Web)</span></p>
            </li>
            <li>
              <figure className="img"><img src="../../../images/educators/img_educators_trial03.png" alt="img"/>
              </figure>
              <p className="txt"><strong>For Student,</strong><span>Use Mathpid (Web or App)</span></p>
            </li>
          </ul>
        </div>
      </section>

      <section className="main-section">
        <h3 className="blind">For Educators</h3>
        <div className="educators-introduce introduce-grid-wrap">
          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/educators/img_educators_intro01.png" alt="Image 1"/>
            </figure>
            <div className="text">
              <h4 className="head">Effortless classroom management, enhanced teaching efficiency</h4>
              <p className="desc">Visualize student performance by <strong>group on the dashboard</strong>, easily
                identify students who need more support, and provide tailored learning solutions. Help students master
                difficult problem types through repeated practice, enabling <strong>more effective lessons and
                  assignments.</strong></p>
            </div>
          </div>
          <div className="introduce-grid-type2">
            <figure className="img">
              <img src="../../../images/educators/img_educators_intro02.png" alt="Image 2"/>
            </figure>
            <div className="text">
              <h4 className="head">Personalized guidance with individual learning reports</h4>
              <p className="desc">With ai-driven individual learning reports, you can analyze students' accuracy, error
                patterns, and study time in detail, <strong>allowing you to target areas of weakness.</strong> The
                system <strong>analyzes both teacher-assigned tasks and self-study activities,</strong> offering a
                customized learning path for each student. Track learning progress at a glance and <strong>keep students
                  motivated toward their goals.</strong></p>
            </div>
          </div>
          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/educators/img_educators_intro03.png" alt="Image 3"/>
            </figure>
            <div className="text">
              <h4 className="head">The secret to improving your class's math scores: <br/>Strengthen foundations and
                build confidence in math!</h4>
              <p className="desc">Arithmetic skills form the basis of math learning, and consistent practice is
                essential for improving math scores. For students struggling with math, reinforcing the basics
                can <strong>significantly reduce learning gaps and raise overall class performance.</strong></p>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-bg-navy">
        <div className="frame-inner">
          <h3 className="title-section">Accelerated learning with personalized study plans <br className="rwd-br-pc"/>and
            self-directed learning habits</h3>
          <ul className="circuit-list">
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list01"></i><span>AI level test</span></dt>
                <dd>Accurately assess each student's level <br className="rwd-br-pc"/>with just 10 questions and provide
                  a tailored learning class.
                </dd>
              </dl>
            </li>
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list02"></i><span>Mistake note</span></dt>
                <dd>Help students reattempt incorrect problems <br className="rwd-br-pc"/>until they fully understand
                  the concepts.
                </dd>
              </dl>
            </li>
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list03"></i><span>Real-time adaptivity</span></dt>
                <dd>Offer the optimal learning difficulty <br className="rwd-br-pc"/>based on each student's pace and
                  level.
                </dd>
              </dl>
            </li>
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list04"></i><span>Global curriculums</span></dt>
                <dd>Offers tailored arithmetic-focused learning aligned <br className="rwd-br-pc"/>with standards from
                  the US, UK, India, and South Korea.
                </dd>
              </dl>
            </li>
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list05"></i><span>PISA-based problem sets</span></dt>
                <dd>Use an AI engine based on OECD's PISA standards <br className="rwd-br-pc"/>to support ongoing
                  learning and enhance top-tier skills.
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>

      <MathpidContent />

      <section className="section section-bg-green">
        <div className="frame-inner">
          <h3 className="title-section">Perfect solution for teachers</h3>
          <ul className="solution-list">
            <li><i className="icon-solution01"></i><span className="txt">Learning managementsystem</span></li>
            <li><i className="icon-solution02"></i><span className="txt">Step-by-step videos for each subtopic</span>
            </li>
            <li><i className="icon-solution03"></i><span className="txt">Auto-grading</span></li>
            <li><i className="icon-solution04"></i><span className="txt">Teacher's guide</span></li>
            <li><i className="icon-solution05"></i><span className="txt">Printable worksheets</span></li>
            <li><i className="icon-solution06"></i><span className="txt">Parent report</span></li>
            <li><i className="icon-solution07"></i><span className="txt">Teacher workshops</span></li>
            <li><i className="icon-solution08"></i><span className="txt">Mistake note functionality</span></li>
            <li><i className="icon-solution09"></i><span
              className="txt">App download (compatible with all devices)</span></li>
          </ul>
        </div>
      </section>
    </main>
  );
}

export default MathpidEducators;