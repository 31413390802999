import React from 'react';
import {dropdownClose, dropdownOpen} from "../lib/util";
import {useAppContext} from "../App";

function MathpidFooter(props) {
  const {langCd, handleChangeLangCd, handlePage} = useAppContext();
  const handleClick = (e, item) => {
    if (item) {
      dropdownClose();
      handleChangeLangCd(item);

    } else {
      dropdownOpen(e.target);
    }

  }
  return (
    langCd === 'ko'
      ? (
        <footer className="footer">
          <div className="frame-inner">
            <div className="footer-menus">
              <ul className="grid-row">
                <li className="col">
                  <a onClick={() => handlePage('educators')} data-page="educators"><strong
                    className="tit">선생님용</strong></a>
                </li>
                <li className="col">
                  <a onClick={() => handlePage('learners')} data-page="learners"><strong
                    className="tit">학생용</strong></a>
                </li>
                <li className="col">
                  <a onClick={() => handlePage('impact')} data-page="impact"><strong
                    className="tit">영향력</strong></a>
                  <ul className="sub">
                    <li>
                      <a onClick={() => handlePage('impact_features')}>매쓰피드 기능</a></li>
                    <li>
                      <a onClick={() => handlePage('impact_reviews')}>리뷰</a></li>
                  </ul>
                </li>
                <li className="col">
                  <a href="https://company.wjthinkbig.com/index.do" target="_blank"><strong
                    className="tit">회사소개</strong></a>
                </li>
                <li className="col">
                  <strong className="tit">Contact us</strong>
                  <ul className="sub">
                    <li>
                      <ul className="sns">
                        <li>
                          <a href="https://www.facebook.com/mathpid/" target="_blank"><i
                          className="icon-sns-facebook"></i><span className="blind">페이스북</span></a></li>
                        <li>
                          <a href="https://www.instagram.com/mathpid/" target="_blank"><i
                          className="icon-sns-instargram"></i><span className="blind">인스타그램</span></a></li>
                        <li>
                          <a href="https://www.youtube.com/channel/UCvmX2CTVg4JpCgGjg8mzIgA" target="_blank"><i
                          className="icon-sns-youtube"></i><span className="blind">유튜브</span></a></li>
                      </ul>
                    </li>
                    <li>
                      <a onClick={() => handlePage('contact')} data-page="contact">문의</a></li>
                    <li>
                      <a onClick={() => handlePage('terms')} data-page="terms">서비스 약관</a></li>
                    <li>
                      <a onClick={() => handlePage('policy')} data-page="policy">개인정보 보호정책</a></li>
                  </ul>
                </li>
              </ul>
              <a href="https://company.wjthinkbig.com/index.do" target="_blank" className="logo"><img
                src={`${process.env.PUBLIC_URL}images/logos/logo_woongjin.png`} alt="웅진씽크빅"/></a>
            </div>
            <div className="footer-info">
              <div className="inner">
                <div className="lang-select">
                  <span>언어</span>
                  <div className="dropdown">
                    <div className="dropdown-inner">
                      <a onClick={(e) => handleClick(e)} className="dropdown-value">
                        <span>한국어</span>
                        <i className="ico-arrow-dropdown"></i>
                      </a>
                      <div className="dropdown-list">
                        <ul>
                          <li>
                            <a onClick={(e) => handleClick(e, 'ko')}>한국어</a></li>
                          <li>
                            <a onClick={(e) => handleClick(e, 'en')}>English</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <address className="address">
                  © 2024, Mathpid by 웅진씽크빅 (141-81-09131) | 경기도 파주시 회동길 20
                </address>
              </div>
            </div>
          </div>
          <button className="btn-top"><i className="icon-gotop"></i><span className="blind">Top</span></button>
        </footer>
      )
      : (
        <footer className="footer">
          <div className="frame-inner">
            <div className="footer-menus">
              <ul className="grid-row">
                <li className="col">
                  <a onClick={() => handlePage('educators')} data-page="educators"><strong className="tit">For
                    Educators</strong></a>
                </li>
                <li className="col">
                  <a onClick={() => handlePage('learners')} data-page="learners"><strong className="tit">For
                    Learners</strong></a>
                </li>
                <li className="col">
                  <a onClick={() => handlePage('impact')} data-page="impact"><strong
                    className="tit">Impact</strong></a>
                  <ul className="sub">
                    <li>
                      <a onClick={() => handlePage('impact_features')}>Discover Mathpid Features</a></li>
                    <li>
                      <a onClick={() => handlePage('impact_reviews')}>Reviews &amp; Testimonials</a></li>
                  </ul>
                </li>
                <li className="col">
                  <a href="https://company.wjthinkbig.com/en/index.do" target="_blank"><strong className="tit">About
                    Us</strong></a>
                </li>
                <li className="col">
                  <strong className="tit">Contact us</strong>
                  <ul className="sub">
                    <li>
                      <ul className="sns">
                        <li>
                          <a href="https://www.facebook.com/mathpid/" target="_blank"><i
                          className="icon-sns-facebook"></i><span className="blind">facebook</span></a></li>
                        <li>
                          <a href="https://www.instagram.com/mathpid/" target="_blank"><i
                          className="icon-sns-instargram"></i><span className="blind">instagram</span></a></li>
                        <li>
                          <a href="https://www.youtube.com/channel/UCvmX2CTVg4JpCgGjg8mzIgA" target="_blank"><i
                          className="icon-sns-youtube"></i><span className="blind">youtube</span></a></li>
                      </ul>
                    </li>
                    <li>
                      <a onClick={() => handlePage('contact')} data-page="contact">Contact Us</a></li>
                    <li>
                      <a onClick={() => handlePage('terms')} data-page="terms">Terms of Service</a></li>
                    <li>
                      <a onClick={() => handlePage('policy')} data-page="policy">Privacy Policy</a></li>
                  </ul>
                </li>
              </ul>
              <a href="https://company.wjthinkbig.com/en/index.do" target="_blank" className="logo"><img
                src={`${process.env.PUBLIC_URL}images/logos/logo_woongjin.png`} alt="Woongjin ThinkBig"/></a>
            </div>
            <div className="footer-info">
              <div className="inner">
                <div className="lang-select">
                  <span>언어</span>
                  <div className="dropdown">
                    <div className="dropdown-inner">
                      <a
                        onClick={(e) => handleClick(e, )}
                        className="dropdown-value">
                        {
                          langCd === 'ko' ? <span>한국어</span> : <span>English</span>
                        }
                        <i className="ico-arrow-dropdown"></i>
                      </a>
                      <div className="dropdown-list">
                        <ul>
                          <li>
                            <a onClick={(e) => handleClick(e, 'ko')}>한국어</a></li>
                          <li>
                            <a onClick={(e) => handleClick(e, 'en')}>English</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <address className="address">
                  © 2024, Mathpid by Woongjin ThinkBig (141-81-09131) | 20, Hoedong-gil, Paju-si, Gyeonggi-do, Republic of
                  Korea
                </address>
              </div>
            </div>
          </div>
          <button className="btn-top"><i className="icon-gotop"></i><span className="blind">Top</span></button>
        </footer>
      )
  );
}

export default MathpidFooter;