import React from 'react';
import MathpidContent from "../../../component/mathpid_content";

function MathpidEducators(props) {
  return (
    <main className="container">
      <section className="top-section-educator">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">학생들의 수학 실력을 쑥쑥! <br/>매쓰피드로 교실을 혁신하세요.</h2>
            <p className="desc">매쓰피드는 AI 기반 맞춤형 수학 학습 솔루션과 <br/><strong>교사용 학급관리시스템 (LMS, Learning Management
              System)</strong>을 제공합니다.</p>
          </div>
        </div>
      </section>

      <section className="section rwd-gutter">
        <div className="trial-box">
          <h3 className="title">지금 매쓰피드를 <br className="rwd-br-mo"/>교실에서 만나보세요</h3>
          <ul className="trial-list">
            <li>
              <figure className="img"><img src="../../../images/educators/img_educators_trial01.png" alt="img"/>
              </figure>
              <p className="txt"><strong>무료 체험 신청</strong></p>
            </li>
            <li>
              <figure className="img"><img src="../../../images/educators/img_educators_trial02.png" alt="img"/>
              </figure>
              <p className="txt"><strong>교사용</strong><span>대시보드 웹 제공</span></p>
            </li>
            <li>
              <figure className="img"><img src="../../../images/educators/img_educators_trial03.png" alt="img"/>
              </figure>
              <p className="txt"><strong>학생용</strong><span>웹 또는 앱 사용</span></p>
            </li>
          </ul>
        </div>
      </section>

      <section className="main-section">
        <h3 className="blind">선생님용 매쓰피드 소개</h3>
        <div className="educators-introduce introduce-grid-wrap">
          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/educators/img_educators_intro01.png" alt="소개 이미지 1"/>
            </figure>
            <div className="text">
              <h4 className="head">편리한 학급 관리, 수업 효율성 증대</h4>
              <p className="desc">대시보드를 통해 <strong>학생들의 성취도를 그룹별로 시각화</strong>하여, 취약한 부분이 많은 학생들을 한눈에 파악하고 맞춤형 학습을 제공할 수
                있습니다. <br/>이를 통해 과반수의 학생들이 어려워하는 문제 유형을 반복하여 학습하도록 돕고, <strong>효과적인 수업과 과제를 구성</strong>할 수 있습니다</p>
            </div>
          </div>
          <div className="introduce-grid-type2">
            <figure className="img">
              <img src="../../../images/educators/img_educators_intro02.png" alt="소개 이미지 2"/>
            </figure>
            <div className="text">
              <h4 className="head">개별 학습 리포트로 완성하는 맞춤 지도</h4>
              <p className="desc">AI 기반 개인별 학습 리포트를 통해 학생의 문제별 정답률, 오답 유형, 학습 시간 등을 상세히 분석하여, <strong>취약 부분을 집중적으로
                보완</strong>할 수 있습니다. 선생님이 부여한 과제 뿐만 아니라 <strong>스스로 학습한 내용까지 분석</strong>하여, 개별 학생에게 최적화된 학습 경로를
                제시합니다.<br/>학습 성장을 한눈에 확인하고, <strong>목표를 향해 꾸준히 나아갈 수 있도록 동기를 부여</strong>합니다.</p>
            </div>
          </div>
          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/educators/img_educators_intro03.png" alt="소개 이미지 3"/>
            </figure>
            <div className="text">
              <h4 className="head">우리 반 수학 성적 향상의 비결 <br/>매쓰피드로 기초 다지고, 수학 자신감 UP!</h4>
              <p className="desc">연산 능력은 수학 학습의 기초가 되므로, 꾸준한 연산 학습은 학생들의 수학 성적 향상에 필수적입니다. 특히, 수학 학습에 어려움을 겪는 학생들의
                경우, <strong>기본기를 강화하여 학급 전체의 평균 학습 성취도를 효과적으로 향상</strong>시킬 수 있습니다.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-bg-navy">
        <div className="frame-inner">
          <h3 className="title-section">맞춤형 학습으로 쑥쑥! <br/>자기주도 학습 습관까지 완성</h3>
          <ul className="circuit-list">
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list01"></i><span>AI 진단테스트</span></dt>
                <dd>단 10개의 문제로 학생 개개인의 정확한 수준을 진단하고, <br className="rwd-br-pc"/>이에 맞는 학습 로드맵을 제공합니다.</dd>
              </dl>
            </li>
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list02"></i><span>오답노트</span></dt>
                <dd>학생들은 자신이 틀린 문제를 반복해서 풀어보고, <br className="rwd-br-pc"/>개념을 완전히 이해할 수 있도록 도와줍니다.</dd>
              </dl>
            </li>
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list03"></i><span>실시간 난이도 반영</span></dt>
                <dd>문제 푸는 학생의 학습 속도와 수준에 맞춰 <br className="rwd-br-pc"/>개인별 최적의 학습 난이도를 제공합니다.</dd>
              </dl>
            </li>
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list04"></i><span>글로벌 맞춤 학습 커리큘럼</span></dt>
                <dd>미국, 영국, 인도, 한국 등 주요 국가 교육과정 기반으로 <br className="rwd-br-pc"/>연산 집중 학습할 수 있도록 맞춤형 학습을 제공합니다.</dd>
              </dl>
            </li>
            <li className="circuit-list-item">
              <dl className="inner">
                <dt><i className="icon-circuit-list05"></i><span>PISA 기반 문제 제출</span></dt>
                <dd>OECD 의 PISA (국제학습평가프로그램) 기준에 맞춰 개발된 <br className="rwd-br-pc"/>AI 엔진을 기반으로 지속 학습을 돕고, 최고 수준의 학습
                  능력을 <br className="rwd-br-pc"/>키울 수 있도록 돕습니다.
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>

      <MathpidContent />

      <section className="section section-bg-green">
        <div className="frame-inner">
          <h3 className="title-section">선생님을 위한 완벽한 솔루션</h3>
          <ul className="solution-list">
            <li><i className="icon-solution01"></i><span className="txt">학급관리시스템</span></li>
            <li><i className="icon-solution02"></i><span className="txt">소주제별 개념 영상</span></li>
            <li><i className="icon-solution03"></i><span className="txt">자동채점</span></li>
            <li><i className="icon-solution04"></i><span className="txt">교사용 가이드</span></li>
            <li><i className="icon-solution05"></i><span className="txt">출력 가능한 워크시트</span></li>
            <li><i className="icon-solution06"></i><span className="txt">학부모 보고서</span></li>
            <li><i className="icon-solution07"></i><span className="txt">교사 시연을 위한 워크샵</span></li>
            <li><i className="icon-solution08"></i><span className="txt">오답 노트 기능</span></li>
            <li><i className="icon-solution09"></i><span className="txt">앱 다운로드 (모든 기기에서 작동)</span></li>
          </ul>
        </div>
      </section>
    </main>
  );
}

export default MathpidEducators;