import React from 'react';

function MathpidContact(props) {
  return (
    <main className="container">
      <section className="top-section-contact">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">문의하기</h2>
            <p className="desc">아래 양식을 작성해 주시면 Mathpid 팀이 곧 답변해 드리겠습니다.</p>
          </div>
        </div>
      </section>
      <section className="section no-gutter">
        <div className="iframe-wrap">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSckaRif-WsBIq-wmYa3zj9u8nI46yrjMFpxSQGyj6shuE5eog/viewform?embedded=true"
            width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0"></iframe>
        </div>
      </section>
    </main>
  );
}

export default MathpidContact;