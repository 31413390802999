import React from 'react';
import {useAppContext} from "../../App";
import {MathpidEducatorsEn, MathpidEducatorsKo} from "./index";

function EducatorsPage(props) {
  const {langCd} = useAppContext();
  return (
    langCd === 'ko'
      ? <MathpidEducatorsKo />
      : <MathpidEducatorsEn />
  );
}

export default EducatorsPage;