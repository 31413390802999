import React from 'react';

function MathpidLearners(props) {
  return (
    <main className="container">
      <section className="top-section-learner">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">우리 아이, 매주 30분으로 수학 자신감 UP!</h2>
            <p className="desc">매일 조금씩 꾸준히 학습하며, 놀라운 성장을 경험해 보세요.</p>
          </div>
        </div>
      </section>

      <div className="photo-area">
        <figure className="img rwd-img-mo"><img src="../../../images/learners/img_learners_main01_m.png"
                                                alt="just 30 minutes a week with mathpid"/></figure>
        <figure className="img rwd-img-pc"><img src="../../../images/learners/img_learners_main01.png"
                                                alt="just 30 minutes a week with mathpid"/></figure>
      </div>

      <section className="main-section">
        <h3 className="blind">학생용 매쓰피드 소개</h3>
        <div className="learners-introduce introduce-grid-wrap">
          <div className="introduce-grid-type1">
            <figure className="img">
              <img src="../../../images/learners/img_learners_intro01.png" alt="소개 이미지 1"/>
            </figure>
            <div className="text">
              <h4 className="head">12주 만에 달라진 아이들의 모습을 직접 확인해 보세요!</h4>
              <p className="desc">자녀의 수학 실력 향상을 고민하는 부모님이라면, Mathpid를 경험해 보세요.<br/>미국에서 진행된 베타테스트 결과, Mathpid를 주당 30분
                사용한 학생들은 12주 만에 소화 가능 학습 주제 난이도가 한 학년 수준 상승했습니다.<br/>참여한 20명의 학생들 모두 눈에 띄는 성과를 보였으며, 개별 성취도는 다양하게
                나타났습니다.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-bg-grey">
        <div className="frame-inner">
          <h3 className="title-section">매쓰피드를 경험한 학부모님과 아이들의 솔직한 이야기</h3>
          <p className="title-section-desc">실제 학습자들의 생생한 후기를 통해 매쓰피드의 효과를 직접 확인해 보세요.</p>
          <div className="speech-boxes-list">
            <ul className="grid-column">
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      매쓰피드를 <strong>한 달간 사용한 후,</strong> 제 아이가 수학 문제를 <strong>정확하고 빠르게</strong> 풀기 시작했습니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_kr.png" alt="Korea"/>
                      </figure>
                      <div className="info">
                        <span className="name">학부모</span>
                        <span className="nation">한국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      매쓰피드는 <strong>AI로 아이들이 스스로 학습 결과를 확인</strong>하고, <strong>문제 풀이 동영상</strong>을 통해 자신만의 속도로 학습할 수 있게
                      해 주어, 제가 바쁠 때도 학습을 지원해줍니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="USA"/>
                      </figure>
                      <div className="info">
                        <span className="name">학부모</span>
                        <span className="nation">미국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      아들이 곱셈표에 더 <strong>익숙해지고 자신감이 생겼습니다.</strong> 숙제도 더 빨리 끝내고 시험도 더 잘 봤습니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_uk.png" alt="UK"/>
                      </figure>
                      <div className="info">
                        <span className="name">학부모</span>
                        <span className="nation">영국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      매쓰피드 덕분에 최종 수학 시험에서 <strong>80점 만점에 78점</strong>을 받을 수 있었습니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_in.png" alt="India"/>
                      </figure>
                      <div className="info">
                        <span className="name">학생</span>
                        <span className="nation">인도</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      <strong>문제 풀이 속도와 정확성을 향상</strong>시키는 최고의 앱입니다. 감사합니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_in.png" alt="India"/>
                      </figure>
                      <div className="info">
                        <span className="name">학생</span>
                        <span className="nation">인도</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      <strong>중독성</strong>이 있고 <strong>학습에 최적화</strong>되어 있습니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_kr.png" alt="Korea"/>
                      </figure>
                      <div className="info">
                        <span className="name">학생</span>
                        <span className="nation">한국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section section-bg-navy">
        <div className="frame-inner">
          <div className="learners-ai-list">
            <h3 className="title-section">AI가 우리 아이의 수준에 딱 맞춰 문제를 내줘요!</h3>
            <ul className="rwd-grid-column gap-32">
              <li className="col">
                <div className="rwd-grid-column align-center gap-24">
                  <div className="col img"><i className="icon-learners-ai01"></i></div>
                  <div className="col txt">부족한 부분은 집중 학습할 수 있도록 우리 아이에게 <br/>딱 맞는 수학 레벨을 찾아주는 'AI 튜터와 함께하는 나의 학습'</div>
                </div>
              </li>
              <li className="col">
                <div className="rwd-grid-column align-center gap-24">
                  <div className="col img"><i className="icon-learners-ai02"></i></div>
                  <div className="col txt">수학카메라로 문제를 찍어 바로 해결해주고, 유사 문제 풀이도 반복 가능! <br/>영상으로 개념을 쉽게 이해!</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section no-gutter">
        <h4 className="blind">다운로드</h4>
        <div className="related-links">
          <div className="related-links-contents">
            <p className="desc">초등학생부터 중학생까지, <br/>지금 바로 매쓰피드를 시작하고 <br className="rwd-br-mo"/>우리 아이의 수학 성적을 올려보세요</p>
            <ul className="links-group">
              <li>
                <a href="https://play.google.com/store/apps/details?id=com.wjthinkbig.mathpid&hl=en&pli=1"
                   className="btn-related-link" target="_blank">
                  <img src="../../../images/learners/btn_googleplay.png" alt="구글플레이"/>
                </a>
              </li>
              <li>
                <a href="https://apps.apple.com/us/app/mathpid-ai-math-learning/id1606099580"
                   className="btn-related-link" target="_blank">
                  <img src="../../../images/learners/btn_appstore.png" alt="앱스토어"/>
                </a>
              </li>
              <li>
                <a href="https://class.Mathpid.com" className="btn-related-link" target="_blank">
                  <img src="../../../images/learners/btn_classmathpid.png" alt="매쓰피드"/>
                </a>
              </li>
            </ul>
          </div>
          <figure className="img rwd-img-mo">
            <img src="../../../images/learners/img_learners_link01_m.png" alt="배경 이미지"/>
          </figure>
          <figure className="img rwd-img-pc">
            <img src="../../../images/learners/img_learners_link01.png" alt="배경 이미지"/>
          </figure>
        </div>
      </section>
    </main>
  );
}

export default MathpidLearners;