import React from 'react';

function MathpidPolicy(props) {
  return (
    <main className="container">
      <section className="top-section-terms">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">개인정보처리방침</h2>
          </div>
        </div>
      </section>

      <section className="section no-gutter">
        <div className="terms-policy-wrap">
          <p>매쓰피드 LMS를 이용해주시는 고객 여러분께 감사드리며, 새로운 개인정보처리방침 시행에 관해 안내 말씀드립니다.</p>
          <p>[변경 내용]<br/>수집하는 개인정보의 항목과 이용목적이 구체적으로 정리됩니다. 개인정보수집에 대한 동의 등의 사항이 변경되었습니다.</p>
          <p>[시행일]<br/>2024년 6월 24일</p>
          <p>[개인정보처리방침]</p>
          <p><a
            href="https://cdn.shopify.com/s/files/1/0649/1904/4348/files/Mathpid_Current_Privacy_Policy_ko.pdf?v=1718778746"
            target="_blank">기존의 개인정보처리방침</a></p>
          <p><a
            href="https://cdn.shopify.com/s/files/1/0649/1904/4348/files/Mathpid_ko_Updated_Privacy_Policy.pdf?v=1719899103"
            target="_blank">변경된 개인정보처리방침</a></p>

          <hr/>

          <h3>[매쓰피드 - 개인정보 처리방침]</h3>
          <p>주식회사 웅진씽크빅(이하 "회사"라 한다)이 운영하는 매쓰피드(이하 "서비스"라 한다)는 데이터 및 개인 정보 보호 프로세스를 투명하게 하고 당사 웹 사이트 및 애플리케이션 사용자의 온라인상의
            개인 정보를 보호하기 위해 최선을 다하고 있습니다, 본 개인정보 보호정책은 당사의 앱 및 웹사이트를 통해 당사가 수집한 정보와 정보가 사용 또는 공개되는 방법, 수집한 정보 보호를 위해 어떠한
            조치를 취하고 있는지("개인 정보 보호 정책")에 대해 설명합니다.</p>

          <h4>1. 개인정보의 수집 및 이용목적</h4>
          <p>- 사용자("부모" 및 "자녀")에게 서비스를 제공하기 위해.</p>
          <p>- 사용자의 문의에 응답하고 요청을 이행하기 위해</p>
          <p>- 당사의 서비스에 관한 정보 및 약관, 약관 및 정책의 변경과 같은 행정 정보를 사용자에게 전송하기 위해</p>
          <p>- 당사가 사용자에게 관심을 가질 수 있다고 생각되는 마케팅 목적의 정보를 발송하기 위해</p>
          <p>- 사용자에게 당사의 서비스를 개인화하여 제공하기 위해.</p>
          <p>- 데이터 분석, 감사, 사기 모니터링, 마케팅 및 프로모션, 신제품 개발, 서비스 개선, 사용 패턴 식별, 마케팅에 대한 효율성 판단과 같은 비즈니스 목적으로.</p>
          <p>- 정보 유출 및 기타 부적절한 활동을 해결하기 위해</p>
          <p>- 법적 절차를 준수하고, 이용 약관을 집행하고, 원활한 서비스 운영을 위해, 당사의 권리와 귀하의 권리와 다른 사람의 권리를 보호하는 것이 필요하거나 적절하다고 판단되는 경우.</p>

          <h4>2. 수집하는 개인정보의 종류 및 목적</h4>
          <p>귀하에게 서비스를 제공하는 과정에서 당사와 당사의 서비스 제공업체는 귀하의 정보를 수집합니다, 귀하가 당사에 직접 제공하는 정보가 수집되며 또한 귀하가 당사의 서비스를 사용할 때 발생되는
            정보가 자동으로 수집됩니다.</p>
          <p>당사는 회사의 매각, 합병 등 회사의 권리와 의무를 승계하는 맥락에서 본 정책(2.3정보권)에 설명된 경우를 제외하고 귀하의 정보를 판매하거나 대여하지 않습니다.</p>
          <p>사용자는 학습자(만1 6세 이하 아동, 이하 "자녀"라 한다)와 보호자(법적으로 "자녀"에 대한 부모의 책임을 보유한 자, 이하 "부모"라 한다)로 구분되며, "부모"만 서비스 가입이
            가능합니다.</p>
          <p>"부모"는 서비스에 가입 시 또는 가입 후, "자녀"의 정보를 직접 등록하여 "자녀"가 학습 서비스를 이용할 수 있도록 할 수 있으며, 이러한 정보를 입력함으로써 "부모"는 "자녀"의 정보
            제공에 동의한 것으로 간주합니다.</p>

          <ul>
            <li>서비스 &gt; 학습자 등록
              <p>"부모"는 서비스 가입 시 개인정보제공에 동의 또는 거부할 수 있습니다.</p>
              <p>단, 개인정보제공에 거부 시 매쓰피드 서비스의 정상적인 이용에 제한이 될 수 있습니다.</p>
              <p>사용자로부터 제공 및 수집되는 정보는 당사에서 운용중인 장소에 보관 및 관리 됩니다.</p>
            </li>
            <li>국가 및 장소 : 한국(AWS Asia Pacific (Seoul) Region)</li>
            <li>정보수집자 : 웅진씽크빅</li>
            <li>수집 정보 : 가입자의 E-mail,가입자의 PW,학습자의 별명, 학습자가 업로드한 수학 카메라 이미지</li>
            <li>이전되는 시점 및 방법 : 서비스 이용 시점에 네트워크를 통한 전송</li>
            <li>E-mail : <a href="mailto:mathpid@wjtb.net">mathpid@wjthinkbig.com</a></li>
          </ul>

          <h4>2.1 계정 생성 및 이용을 위해 사용자가 제공하는 정보</h4>
          <p>- 매쓰피드 계정을 생성할 때, "부모"는<span> E-mail </span>과 비밀번호를 당사에 제공합니다, 비밀번호는 암호화 되어 보관되며, 당사는 제공받은 이메일 주소를 통해 서비스의
            업데이트 또는 당사 서비스에 대해 알리고 사용자가 관심을 가지게 될 것으로 생각되는 마케팅 이메일을 보내고, 사용자가 당사에 연락할 때 사용자에게 응답할 수 있습니다, 그리고 이 수집된 정보를
            통해서 등록된 "자녀"의 학습 상황을 알리거나 개인화와 같은 추가 서비스를 제공합니다.</p>
          <ul>
            <li>서비스 &gt; 메인홈 &gt; 학습 리포트 &gt; "자녀"의 학습 진도</li>
            <li>서비스 &gt; 메인홈 &gt; 학습 리포트 &gt; "자녀"의 학습 현황 분석</li>
            <li>서비스 &gt; 메인홈 &gt; 학습 리포트 &gt; "자녀"의 진단결과</li>
          </ul>
          <p> - "부모"가 등록한, "자녀" 의 별명이 당사에 제공됩니다, 당사에 제공된 "자녀"의 별명은 "자녀"에게 개인화된 더 나은 학습 경험과 환경을 제공하고 당사 서비스의 성능과 설계를 개선하기
            위한 당사의 합법적인 목적을 위해 사용됩니다.</p>
          <ul>
            <li>서비스 &gt; 메인</li>
            <li>서비스 &gt; 메인홈 &gt; 나의 학습 &gt; AI 레벨 테스트</li>
            <li>서비스 &gt; 메인홈 &gt; 학습 리포트</li>
          </ul>
          <p>- 당사는 서비스에 필요한 정보만 수집합니다, 정보를 판매하거나 임대하지 않으며 광고에 대한 프로필을 만들지 않습니다.</p>

          <h4>2.2 자동으로 수집되는 정보</h4>
          <p>- 귀하가 당사의 서비스를 처음 이용할 때, 당사는 귀하가 서비스를 사용하는 방법에 대한 기술정보, 식별자 및 데이터를 자동으로 수집할 수 있습니다, 수집되는 데이터에는 당사의 서비스에 액세스
            하는데 사용하는 장치 및 브라우저 정보(장치<span>ID), IDFA, </span>고유 식별자, 브라우저 유형 및 설정, 장치 유형 및 설정, 운영 체제, 이동통신사 이름을 포함한 모바일
            네트워크 정보 및 응용프로그램 버전 정보<span>, IP</span>주소, 충돌 보고서, 시스템 활동, 요청 날짜 및 시간, 당사 서비스와 사용자간의 상호작용에 대한 정보, 위치정보<span>(GPS, IP</span>주소,
            기기의 센서 데이터<span>, Wi-Fi </span>액세스 포인트 등)가 포함될 수 있습니다.</p>
          <p>- 또한 당사는 귀하가 서비스를 이용하는 과정에서 발생하는 데이터를 수집할 수 있습니다, 수집되는 데이터에는 학습 과목 및 문항, 정답 결과, 오답 결과, 학습 날짜 및 시간, 학습 빈도가
            포함될 수 있습니다.</p>
          <p>- 당사는 쿠키, 픽셀태그, 브라우저 웹 스토리지 또는 응용 프로그램 데이터 캐시, 데이터베이스 및 서버 로그를 포함한 다양한 기술을 사용하여 정보를 수집하고 저장합니다.</p>
          <p>- 당사의 앱 및 서비스에서 수집된 모든 정보는 귀하와 귀하의 학생에게 더 나은 사용자 및 학습 경험을 제공하고 당사 서비스의 성능과 설계를 개선하기 위한 당사의 합법적인 목적을 위한
            것입니다, 또한 당사는 이메일 주소를 사용하여 당사의 서비스에 대해 귀하와 소통하고 귀하가 당사에 연락할 때 귀하에게 응답할 수 있습니다.</p>

          <h4>2.3 정보권</h4>
          <p>- 당사는 본 ‘개인정보 보호정책’에 명시된 경우 또는 관련 법률에 따라 달리 허용되는 경우를 제외하고, 당사는 다음을 제외한 제<span>3</span>자와 수집한 정보를 공유, 공개 또는
            판매하지 않습니다.</p>
          <ul>
            <li>당사는 클라우드 서비스, 데이터 분석 및 처리와 같은 서비스를 운영하기 위한 서비스 제공업체를 사용합니다, 이러한 서비스 제공 업체는 당사가 계약하는 서비스에 대해서만 정보를 사용하며,
              본 개인정보 보호정책과 동일한 수준의 데이터 보호를 준수 합니다.
              <p>* 국가 및 장소 : 한국<span>(AWS Asia Pacific (Seoul) Region)</span></p>
              <p>* 업체명<span> : Amazon Web Services, Inc.</span></p>
              <p>* 이전 일시 및 방법 : 서비스 이용 시점에 네트워크를 통한 전송</p>
              <p>* 개인정보보호 대리인 : 제너럴에이전트 주식회사(대표 김영수)</p>
              <p>* 주소 : 서울 종로구 새문안로<span>5</span>가길<span> 28,</span></p>
              <p>* 연락처<span> : 822-735-6888,</span></p>
              <p>* 이메일<span> : AWS@generalagent.co.kr</span></p>
            </li>
            <li>당사의 합병, 회사 자산 매각 또는 회사의 전부 또는 일부를 다른 회사에 대한 협상 중 또는 그 사람의 개인 안전을 보호하기 위한 비상 사태에 관련된 경우 당사는 새로운 소유자가 본 개인
              정보 보호 정책의 조건을 계속 준수하도록 요구하거나, 당사는 사용자에게 데이터를 삭제하여 양도를 거부할 수 있는 통지와 기회를 제공할 것입니다
            </li>
          </ul>

          <h4>2.4 개인 정보의 열람, 수정, 동의 철회 등 정보 주체의 권리 및 행사 방법</h4>
          <p>- 당사는 사용자의 개인정보에 대해 열람, 수정, 동의 철회 등의 요구가 있을 시 지체 없이 이를 처리합니다, 따라서 사용자는 언제든지 등록되어 있는 자신 혹은 당해
            만<span> 16</span>세 미만 아동의 개인 정보를 열람 하거나 수정할 수 있으며 동의 철회를 요청할 수도 있습니다.</p>
          <p>- 사용자의 개인 정보 열람<span>/</span>수정을 위해서는 ‘개인 정보 변경’(또는 ‘회원 정보 수정’ 등)을, 동의 철회를 위해서는 ‘회원 탈퇴’를 클릭하여 본인 확인 절차를 거치신
            후 직접 열람, 정정 또는 탈퇴가 가능합니다, 혹은 대표 전화<span>(1577-1500) </span>또는 개인 정보 관리자에게 서면이나 전화로 연락하시면 지체 없이 조치 받을 수 있습니다.
          </p>
          <p>- 사용자가 개인 정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다, 또한 잘못된 개인 정보를 본 개인정보보호청책에 명시된
            제<span>3</span>자에게 이미 제공한 경우에는 정정 처리 결과를 제<span>3</span>자에게 통지하여 정정이 이루어지도록 처리합니다.</p>
          <p>- 사용자의 요청에 의해 해지 또는 삭제된 개인 정보는 수집 시 고지한 ‘개인 정보의 보유 및 이용 기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리됩니다,
            단, 다음의 경우에는 요청을 제한 또는 거절할 수 있고, 당사는 이를 사용자에게 지체 없이 고지합니다.</p>
          <ul>
            <li>법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</li>
            <li>다른 사람의 생명, 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</li>
            <li>개인 정보를 처리하지 아니하면 회원과 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 회원이 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우</li>
          </ul>

          <h4>3. 개인정보의 보관 및 파기</h4>
          <p>- 당사는 원칙적으로 사용자의 삭제 요청, 개인 정보 이용 목적 달성, 개인 정보 보유 및 이용 기간의 경과 후에는 해당 사용자가 제공한 개인 정보를 지체없이 파기합니다, 단, 사용자의 개인
            정보 보유 기간에 대해 별도의 동의를 얻은 경우 또는 법령에서 일정 기간 보관 의무를 부과하는 경우에는 해당 기간 동안 개인 정보를 별도의 데이터베이스<span>(DB)</span>로 옮기거나
            보관 장소를 달리하여 안전하게 보관합니다.</p>
          <p>- 서비스 가입 시 사용자의 개인 정보 보유 기간에 대하여 동의를 얻은 경우는 아래와 같습니다.</p>
          <ul>
            <li>정보통신망 이용 촉진 및 정보 보호 등에 관한 법률 제<span>50</span>조에 따라 사용자의 개인 정보는 동종의 재화 등에 대한 서비스 연장 및 신규 서비스 안내를 위하여 수집 시
              동의해 주신 보유 기간(계약 종료일로부터<span> 6</span>개월)동안 보관 후 파기합니다.
            </li>
          </ul>
          <p>- 그 외 관계 법령에 따른 보유 및 이용 기간은 아래와 같습니다.</p>
          <p>&nbsp;&nbsp; * 전자상거래 등에서의 소비자 보호에 관한 법률</p>
          <ul>
            <li>계약 또는 청약 철회 등의 공급 기록<span> : 5</span>년</li>
            <li>대금결제, 재화 등의 공급 기록<span> : 5</span>년</li>
            <li>소비자 불만 또는 분쟁 처리에 관한 기록<span> : 3</span>년</li>
            <li>표시·광고에 관한 기록<span> : 5</span>개월</li>
          </ul>
          <p>- 자동으로 수집된 정보는 별도의 요청이 없는 한 무기한 보관 되며, 당사 서비스의 개선, 신제품의 개발, 성능의 개선을 위해 사용됩니다.</p>
          <p>- 파기 절차 및 방법</p>
          <ul>
            <li>파기 대상 개인 정보는 당사의 개인 정보 관리자의 승인을 받아 파기합니다.</li>
            <li>전자 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 종이 문서인 경우 분쇄하거나 소각하여 파기합니다.</li>
          </ul>

          <h4>4. 광고 및 결제</h4>
          <p>- 매쓰피드의 서비스에는 제<span>3</span>자 광고가 없습니다, 당사 "서비스"의 이벤트, 업데이트, 학습에 도움이 되는 정보와 같이 당사 "서비스"에 대한 홍보 및 광고만 게재되며,
            사용자가 제공한 개인정보를 활용하거나 공개하지 않습니다.</p>
          <p>- 사용자의 인앱 상품 결제 관련 정보는 모두 구글에서 관리하며, 당사에는 저장하지 않습니다.</p>

          <h4>5. 개인 데이터 처리를 위한 법적 기반</h4>
          <p>- 귀하가 유럽 연합<span>(EU)</span>의 개인 또는<span> EU </span>시민인 경우, 당사는 해당<span> EU </span>법에 따라 귀사에 대한 법적 근거가 있는
            경우에만 귀사에 대한 데이터를 수집하고 처리합니다, 즉, 다음과 같은 경우에만 데이터를 수집하고 처리합니다.</p>
          <ul>
            <li>사기 방지, 서비스 개선, 서비스 및 네트워크 인프라의 보안 향상과 같은 합법적인 이익(개인 정보 보호를 침해하지 않는)을 위해 필요합니다.</li>
            <li>본 수집 및 처리에 동의한 경우</li>
            <li>계약상의 의무를 이행할 필요가 있는 경우</li>
            <li>법적 의무를 준수하기 위해</li>
          </ul>

          <p>개인 데이터를 처리하기 위해 사용자가 동의한 경우, 사용자는 언제든지 동의를 철회하거나 거부할 수 있습니다, 동의를 취소하려면 개인 권리 요청 연락처 섹션에 있는 정보를 사용하여 당사에
            문의하십시오, 합법적 이익을 위해 처리 중인 데이터의 몇 가지 예는 다음과 같습니다.</p>
          <ul>
            <li>네트워크 및 정보 보안<span>(IP </span>주소, 장치<span> ID)</span>
            </li>
            <li>고객 지원 및 부정 행위 방지(이메일 주소)
            </li>
            <li>제품 및 서비스(하드웨어 정보, 작업 로그<span>) </span>개선</li>
          </ul>
          <p>- 당사의 이익에 따라 귀하의 개인 데이터를 처리하는 경우, 귀하는 이의를 제기할 권리가 있습니다, 귀하의 개인 데이터를 수집하고 사용하는 법적 근거에 대해 질문이 있거나 추가 정보가 필요한
            경우<span> mathpid@wjthinkbig.com</span>으로 문의하십시오.</p>

          <h4>6. 개인 정보에 관한 민원 서비스</h4>
          <p>- 당사는 회원의 개인 정보를 보호하고 개인 정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 책임자를 지정하고 있습니다.</p>
          <ul>
            <li>개인 정보 보호 책임자 및<span> DPO: </span>경영지원실 이수종 실장</li>
            <li>개인 정보 관리자 : 정보보호팀 안원필 팀장</li>
            <li>연락처<span> : 1577 </span>-<span> 1500</span></li>
          </ul>

          <p>- 매쓰피드 서비스를 이용하며 발생하는 모든 개인 정보 보호 관련 민원을 개인 정보 관리자 혹은 담당 부서로 신고할 수 있고, 당사는 사용자의 신고 사항에 대해 신속하고 충분한 조치를 취할
            것입니다.</p>
          <p>- 기타 개인 정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
          <ul>
            <li>대검찰청 사이버범죄수사단<span> http://spo.go.kr </span>☎ 국번없이<span> 1301</span></li>
            <li>경찰청사이버안전국<span> https://ecrm.cyber.go.kr/minwon/main </span>☎ 국번없이<span> 182</span></li>
            <li>개인정보침해신고센터<span> http://privacy.kisa.or.kr </span>☎ 국번없이<span> 118</span></li>
            <li>개인정보분쟁조정위원회<span> http://kopico.go.kr </span>☎<span> 02-1833-6972</span></li>
          </ul>

          <h4>7. 정보 보안 및 위반 통지</h4>
          <p>- 당사는 수집된 정보의 보안을 보호하기 위해 최선의 노력을 기울입니다, 당사는 무단 액세스를 방지하고, 데이터 보안을 유지하고, 수집한 정보를 적절하게 사용하는 데 도움이 되는 적절한
            물리적, 기술적 및 절차적 안전 장치를 마련하기 위해 최선의 노력을 기울이고 있습니다.</p>
          <ul>
            <li>전송 되는 데이터를 암호화합니다</li>
            <li>개인 정보 보호 법률 및 모범 사례를 최신으로 유지하고 제품 설계에서 서비스 출시에 이르기까지 개인 정보 보호 고려 사항의 중요성에 대한 교육을 받고 있습니다.</li>
            <li>서비스 운영을 위해 꼭 필요한 직원 이외에는 데이터에 접근하는 것을 제한합니다.</li>
            <li>모든 잠재적인 보안 침해를 방지할 수는 없겠지만 처리하고 관리하는 정보에 대해 합리적인 보안을 제공하기 위해 최선의 노력을 하고 있습니다.</li>
          </ul>
          <p>- 제<span>3</span>자로부터 개인 식별 정보의 무단 액세스를 인식하고 정보가 유해한 방법으로 사용될 수 있다고 판단되는 경우, 당사는 귀하가 당사에 제공한 이메일 주소를 통해
            귀하에게 연락을 시도하거나 또는 앱 또는 웹사이트를 통해 이를 통지합니다.</p>
          <p>본 방침은<span> 2023</span>년<span> 11 </span>월 <span>24</span>일부터 시행되며, 이를 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여
            공지하겠습니다.</p>
        </div>
      </section>
    </main>
  );
}

export default MathpidPolicy;