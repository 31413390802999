import React from 'react';

function MathpidImpactReviews(props) {
  return (
    <main className="container">
      <section className="top-section-review">
        <div className="frame-inner">
          <div className="text-column">
            <h2 className="title">사용자 리뷰 &amp; 추천사</h2>
            <p className="sub-title">실제 경험이 만드는 놀라운 변화</p>
            <figure className="img"><img src="../../../images/impact/img_review_main.png" alt="리뷰 이미지"/></figure>
            <p className="desc">사용자들이 입증한 놀라운 효과, <br className="rwd-br-mo"/><span
              className="text-primary">Mathpid</span>로 수학 실력을 빠르게 향상시키세요.</p>
            <p className="desc-more">미국 학생들을 대상으로 한 실험 결과, 매쓰피드를 보조 학습 도구로 12주 동안 주당 34분씩 사용한 학생들은 <br/>수학 실력이 평균 7단계
              향상되었으며, 이는 소화 가능한 소주제에서 1개 학년 이상 레벨업을 의미합니다.<br/>부모님들은 그 결과에 매우 만족하고 있습니다!</p>
          </div>
        </div>
      </section>

      <section className="section no-gutter">
        <div className="photo-scroll ui-photoscroll-slide swiper">
          <ul className="list swiper-wrapper">
            <li className="swiper-slide">
              <figure className="img"><img src="../../../images/impact/img_review_photo01.png" alt="리뷰 이미지"/></figure>
            </li>
            <li className="swiper-slide">
              <figure className="img"><img src="../../../images/impact/img_review_photo02.png" alt="리뷰 이미지"/></figure>
            </li>
            <li className="swiper-slide">
              <figure className="img"><img src="../../../images/impact/img_review_photo03.png" alt="리뷰 이미지"/></figure>
            </li>
            <li className="swiper-slide">
              <figure className="img"><img src="../../../images/impact/img_review_photo04.png" alt="리뷰 이미지"/></figure>
            </li>
          </ul>
        </div>
      </section>

      <section className="section section-bg-grey">
        <div className="frame-inner">
          <h3 className="title-section">선생님들의 사용후기</h3>
          <div className="speech-boxes-list">
            <ul className="grid-column">
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      50명의 학생들을 대상으로 매쓰피드를 사용했는데, 학생들의 수학 연습 문제를 단 몇 분 만에 <strong>자동으로 채점해줘서 제 업무량이 크게 줄어들었습니다.</strong>"
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_ph.png" alt="필리핀"/>
                      </figure>
                      <div className="info">
                        <span className="name">선생님</span>
                        <span className="nation">필리핀</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      선생님에게 의존하던 학생이 <strong>스스로 학습하는 학생으로 변화</strong>하는 데 정말 유용하고 도움이 된다고 생각합니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="미국"/>
                      </figure>
                      <div className="info">
                        <span className="name">선생님, 홈스쿨링 부모</span>
                        <span className="nation">미국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      <strong>체계적인 학습 접근법과 깊이있게 다루는 주제</strong>는 매쓰피드의 큰 장점입니다. 특히 소수 곱셈의 4단계 학습과 <strong>노트패드
                      기능</strong>이 매우 유용합니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_uk.png" alt="영국"/>
                      </figure>
                      <div className="info">
                        <span className="name">수학 선생님</span>
                        <span className="nation">영국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      <strong>학습 격차가 있는 학생들에게 맞춤형 학습을 제공</strong>할 수 있어 매우 만족합니다! 다른 서비스에 비해 더 개별화된 피드백을 제공하는 점이 마음에
                      듭니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="미국"/>
                      </figure>
                      <div className="info">
                        <span className="name">Grade 6 수학 선생님</span>
                        <span className="nation">미국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      학기 초의 <strong>AI 레벨 테스트는 개별 학생 수준</strong>을 평가하는 데 탁월한 도구입니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="미국"/>
                      </figure>
                      <div className="info">
                        <span className="name">Grade 6 수학 선생님</span>
                        <span className="nation">미국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      매쓰피드의 대주제 내 <strong>다양한 소주제</strong>들이 마음에 들고, 학생들이 <strong>노트패드 기능</strong>을 유용하게 사용합니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_uk.png" alt="영국"/>
                      </figure>
                      <div className="info">
                        <span className="name">Year 6 수학 선생님</span>
                        <span className="nation">영국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      매쓰피드를 <strong>한 달간 사용한 후</strong>, 제 아이가 수학 문제를 <strong>정확하고 빠르게</strong> 풀기 시작했습니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_kr.png" alt="한국"/>
                      </figure>
                      <div className="info">
                        <span className="name">학부모</span>
                        <span className="nation">한국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      매쓰피드는 <strong>AI로 아이들이 스스로 학습 결과를 확인</strong>하고, <strong>문제 풀이 동영상</strong>을 통해 자신만의 속도로 학습할 수 있게
                      해 주어, 제가 바쁠 때도 학습을 지원해줍니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_usa.png" alt="미국"/>
                      </figure>
                      <div className="info">
                        <span className="name">학부모</span>
                        <span className="nation">한국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      아들이 곱셈표에 더 <strong>익숙해지고 자신감이 생겼습니다</strong>. 숙제도 더 빨리 끝내고 시험도 더 잘 봤습니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_uk.png" alt="영국"/>
                      </figure>
                      <div className="info">
                        <span className="name">학부모</span>
                        <span className="nation">영국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      매쓰피드 덕분에 최종 수학 시험에서 <strong>80점 만점에 78점</strong>을 받을 수 있었습니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_in.png" alt="인도"/>
                      </figure>
                      <div className="info">
                        <span className="name">학생</span>
                        <span className="nation">인도</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      <strong>문제 풀이 속도와 정확성을 향상</strong>시키는 최고의 앱입니다. 감사합니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_in.png" alt="인도"/>
                      </figure>
                      <div className="info">
                        <span className="name">학생</span>
                        <span className="nation">인도</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col">
                <div className="speech-box">
                  <div className="inner">
                    <div className="message">
                      <strong>중독성</strong>이 있고 <strong>학습에 최적화</strong>되어 있습니다."
                    </div>
                    <div className="bottom">
                      <figure className="flag">
                        <img src="../../../images/icons/flag_kr.png" alt="한국"/>
                      </figure>
                      <div className="info">
                        <span className="name">학생</span>
                        <span className="nation">한국</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MathpidImpactReviews;