import React from 'react';
import {useAppContext} from "../../App";
import {MathpidLearnersEn, MathpidLearnersKo} from "./index";

function LearnersPage(props) {
  const { langCd } = useAppContext();
  return (
    langCd === 'ko'
      ? <MathpidLearnersKo />
      : <MathpidLearnersEn />
  );
}

export default LearnersPage;