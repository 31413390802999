import React, {useEffect} from 'react';
import{ getLanguageCode } from '../../lib/util';
import {MathPidMainEn, MathPidMainKo} from './index';
import {useAppContext} from "../../App";

function MainPage(props) {

	const {langCd} = useAppContext();

	useEffect(() => {
		var swiper = new window.Swiper('.ui-quicklink-slide', {
			slidesPerView: 1,
			spaceBetween: 10,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			breakpoints: {
				580: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				890: {
					slidesPerView: 3,
					spaceBetween: 10,
				}
			},
		})
	})

	return (
		langCd === 'ko'
			? <MathPidMainKo />
			: <MathPidMainEn />
	);
}

export default MainPage;